import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../internal.classNames';
import { ComboBox, Dropdown, IComboBoxOption, Label, TextField } from '@fluentui/react';
import _ from 'lodash';
import { getGenericModal } from '../../../../../redux/selectors/ui';
import { ExternalUserForm, Role } from '../../../../../models/administration';
import { ModalDocumentAction } from '../../../../../redux/actions/modal';
import { allCountries } from 'country-region-data';
import { getAppRolesDropdownOptions, getNotificationsDropdownOptions, getPossibleExternalRolesByCompany } from '../../../../../utils/functions';
import { getAllAdminNotifications, getSelectedProjectExternalAppRoles } from '../../../../../redux/selectors/administration';
import { getStakeHolders } from '../../../../../redux/reducers/sharedData';
import { StakeHolder } from '../../../../../models/stakeHolder';
import { ExternalRolesType } from '../../../../../utils/costants';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getEventTypes } from '../../../../../redux/reducers/notifySettings';
import { NotifyRule } from '../../../../../models/notifyRule';
import { EventType } from '../../../../../models/eventType';

export interface NewExternalUserProps extends RouteComponentProps, WithTranslation {
    externalUser: ExternalUserForm,
    selectedProjectAppRoles: Role[],
    stakeHolders: StakeHolder[],
    allNotifications: NotifyRule[]
    eventTypes: EventType[],
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty
}

class NewExternalUser extends React.Component<NewExternalUserProps> {
    constructor(props: NewExternalUserProps) {
        super(props);   
    }

    render(){
        const { externalUser, selectedProjectAppRoles, stakeHolders, allNotifications, eventTypes, t, setContextItemProperty } = this.props;
        const styles = getClassNames();
        const country_options: IComboBoxOption[] =  _.map(allCountries, (c) => {return {key: c[0], text: c[0]}});
        const stakeholder_options: IComboBoxOption[] =  _.map(stakeHolders, (c) => {return {key: c.companyName as string, text: c.companyName as string}});
        let externalRoles = (externalUser && externalUser.company && stakeHolders && stakeHolders.length > 0) ? _.filter(selectedProjectAppRoles, r => getPossibleExternalRolesByCompany(externalUser.company as string, stakeHolders).includes(r.name as ExternalRolesType)) : [];
        let role_options: IComboBoxOption[] = _.filter(getAppRolesDropdownOptions(externalRoles), r => r.text !== 'NONE');
        let notifications_options = getNotificationsDropdownOptions(allNotifications, eventTypes, true);
        let selectedOptionNames = (externalUser) ? _.filter(notifications_options, o => _.map(externalUser.notifications, n => n.eventTypeId as number).includes(o.key)) : [];
        return (
            <>
                {externalUser &&    
                    <>
                        <TextField 
                            required
                            label={t("Email")} 
                            autoComplete={'off'} 
                            value={externalUser.email} 
                            onChange={(e, value) => { setContextItemProperty({id: 'newExternalUser', name: 'email', value})}}
                            errorMessage={(externalUser.errors && externalUser.errors.email) ? externalUser.errors.email : undefined}
                        />   
                        <ComboBox
                            required
                            label={t("Company")}  
                            placeholder={t("Select a company") + "..."}
                            selectedKey={externalUser.company}
                            options={stakeholder_options}
                            onChange={(e, value) => setContextItemProperty({id: 'newExternalUser', name: 'company', value: (value as IComboBoxOption).key as string})}                           
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 320}}
                            errorMessage={(externalUser.errors && externalUser.errors.company) ? externalUser.errors.company : undefined}
                        />     
                        <ComboBox
                            required
                            label={t("Country")} 
                            placeholder={t("Select a country") + "..."}
                            selectedKey={externalUser.country}
                            options={country_options}
                            onChange={(e, value) => setContextItemProperty({id: 'newExternalUser', name: 'country', value: (value as IComboBoxOption).key as string})}                           
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 320}}
                            errorMessage={(externalUser.errors && externalUser.errors.country) ? externalUser.errors.country : undefined}
                        />     
                        <ComboBox
                            required
                            label={t("Role")} 
                            options={role_options}
                            disabled={!externalUser || (externalUser && !externalUser.company)}
                            placeholder={t("Select a role") + "..."}
                            onChange={(e, value) => setContextItemProperty({id: 'newExternalUser', name: 'role', value: {..._.find(selectedProjectAppRoles, r => r.azureName === (value as any).azureName )}})}
                            selectedKey={(externalUser.role) ? externalUser.role.id : null}  
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 220}}
                            errorMessage={(externalUser.errors && externalUser.errors.role) ? externalUser.errors.role : undefined}   
                        />      
                        <ComboBox
                            label="Notifications"
                            multiSelect
                            options={notifications_options}
                            placeholder={'Notifications...'}
                            title={(selectedOptionNames.length > 0) ? _.join(_.map(selectedOptionNames, o => o.text), ', ') : undefined}
                            onChange={(e, value) => {
                                let userNotification = (externalUser.notifications) ? _.find(externalUser.notifications, r => value && r.eventTypeId === value.key ) as NotifyRule : undefined;
                                let newNotification = (!userNotification) ?  _.find(allNotifications, r => value && r.eventTypeId === value.key ) as NotifyRule : undefined;
                                setContextItemProperty({id: 'newExternalUser', name: 'notifications', value: (!userNotification && newNotification) ? [...externalUser.notifications as NotifyRule[], newNotification] : [... _.filter(externalUser.notifications, n => userNotification && n.eventTypeId !== userNotification.eventTypeId) as NotifyRule[]]})    
                            }}
                            selectedKey={(externalUser.notifications) ? _.map(externalUser.notifications, n => n.eventTypeId as number) : []}       
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 220}}       
                        />          
                    </>             
                }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    externalUser: (state) => (getGenericModal('newExternalUser')(state)) ? (getGenericModal('newExternalUser')(state) as any).contextItem : undefined,
    selectedProjectAppRoles: getSelectedProjectExternalAppRoles,
    stakeHolders: getStakeHolders,
    allNotifications: getAllAdminNotifications,
    eventTypes: getEventTypes,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalDocumentAction.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(NewExternalUser)));