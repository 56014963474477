import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../index.classNames';
import { Text, FontIcon, Icon, FontSizes, IContextualMenuProps, IconButton } from '@fluentui/react';
import _ from 'lodash';
import { FileGrid, FileToDelete, getEmptyFileForm, getEmptyParentFileForm, OtherRevision } from '../../../../models/document';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { CommandActions as ModalCommandActions } from '../../../../redux/actions/modal';
import { CommandActions as DocumentsCommandActions } from '../../../../redux/actions/documents';
import { getFileExtension, getHistoryName, showParentFileChangeIcon } from '../../../../utils/functions';
import { Status } from '../../../../models/status';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getEmail } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { getTheme } from '../../../../utils/costants';
import { getDarkModeEnabled } from '../../../../redux/reducers/project';

export interface FilesProps extends RouteComponentProps, WithTranslation {
    documentId: number,
    isCurrent: boolean,
    status: Status,
    customerDocNumber?: string
    customerDocIndex?: string
    projectDocumentNumber?: string
    files: FileGrid[],
    canDeleteFiles: boolean,
    canUplaodAttachments: boolean,
    canUploadFileComments: boolean,
    clientNumber?: string,
    email?: string,
    darkModeEnabled: boolean,
    openGenericModal: typeof ModalCommandActions.openModal,
    downloadOrShowFile: typeof DocumentsCommandActions.downloadOrShowFile
}

class Files extends React.Component<FilesProps & RouteComponentProps> {
    constructor(props: FilesProps) {
        super(props);   
    }

    deleteFile = (id: string, fileToDelete: FileToDelete, email?: string,  openGenericModal?: typeof ModalCommandActions.openModal) => {
        if(fileToDelete && fileToDelete.file && fileToDelete.file.createdBy && fileToDelete.file.createdBy !== email)
          window.location.href = "mailto:" + fileToDelete.file.createdBy + "?subject=Exod - File " + fileToDelete.file.fileName + " deleted by " + email;
        if(openGenericModal)
          openGenericModal({id: 'deleteFile', optional: {...fileToDelete}})
    }
      

    render(){
        const { files, projectDocumentNumber, documentId, isCurrent, canUplaodAttachments, canUploadFileComments, history, status, customerDocIndex, email, darkModeEnabled, customerDocNumber, t, openGenericModal, downloadOrShowFile } = this.props;
        const orderedFiles = _.orderBy(files, ['isParent', 'isComment', 'fileName'], ['desc', 'asc', 'asc']);
        const styles = getClassNames();
        let menuButtonProps: IContextualMenuProps = {items: [], directionalHintFixed: true}
        _.forEach(_.filter(files, f => f.isParent), a => {
            menuButtonProps = {
              items: [
                {
                  key: 'docNumbName',
                  text: 'Use SMS Doc.Number as filename',
                  onClick: () => {downloadOrShowFile({id: a.id, fileName: a.fileName})}
                },
                {
                  key: 'clientNumbName',
                  text: 'Use Customer Doc.Number as filename',
                  onClick: () => {downloadOrShowFile({id: a.id, fileName: a.fileName, clientNumber: (customerDocNumber + '_' + customerDocIndex)})},
                  disabled: !customerDocNumber
                },
                {
                    key: 'projectNumbName',
                    text: 'Use Project Doc.Number as filename',
                    onClick: () => {downloadOrShowFile({id: a.id, fileName: a.fileName, clientNumber: projectDocumentNumber})},
                    disabled: !projectDocumentNumber            
                },
              ],
              directionalHintFixed: true,
            };
          });

        return (
            <>
                <div className={styles.tabContainer}>     
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 30, gap: 20}}>      
                        <div><Text variant='xLarge' nowrap>{t('Files')}</Text></div>    
                        { isCurrent && status && status.name !== 'canceled' && (canUplaodAttachments || canUploadFileComments) &&
                            <>
                                { _.some(files, f => f.isParent) &&
                                    <FontIcon 
                                        iconName={'Add'} 
                                        title={t('Upload files')} 
                                        onClick={() => {openGenericModal({id: 'uploadDocuments', contextItem: {files: [], fileForm: getEmptyFileForm(canUplaodAttachments, canUploadFileComments)}})}}
                                        style={{cursor: 'pointer', fontSize: FontSizes.large, color: getTheme(darkModeEnabled).palette.themePrimary}}>
                                    </FontIcon>
                                }
                                { files && files.length > 0 &&
                                    <FontIcon 
                                        iconName={t('Download')} 
                                        title={t('Download files')} 
                                        onClick={() => {openGenericModal({id: 'fileName', optional: documentId})}}
                                        style={{cursor: 'pointer', fontSize: FontSizes.large, color: 'green'}}>
                                    </FontIcon> 
                                } 
                            </>
                        }
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: 15, maxWidth: 460}}>
                        {_.map(orderedFiles, (a, index) => {
                            let fileToDelete: FileToDelete = {documentId, file: {...a}};
                            return(
                                <div key={index + '_fileDiv'} style={{display: 'flex', justifyContent: 'space-between', padding: 8, alignItems: 'center'}}>  
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Icon key={index + '_i'} {...getFileTypeIconProps({extension: (a.fileName) ? getFileExtension(a.fileName) : '', size: 20}) }/>
                                        <Text key={index + '_t'} variant="medium" style={{marginLeft: 10, fontWeight: (a.isParent) ? 'bold' : undefined}}>{a.fileName}</Text> 
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', gap: (!a.isParent) ? 15 : 10}}>
                                        {/* {canDeleteFiles && !a.isParent && <FontIcon iconName={"Cancel"} title={t("Delete file")} style={{cursor: "pointer", color: 'red', marginTop: 2, fontSize: 16 }} onClick={() => {this.deleteFile('deleteFile', fileToDelete, email, openGenericModal)}}/>  } */}
                                        <Text key={index + '_p'} variant="medium" style={{}}>{(a.isComment) ? '[C]' : ((!a.isComment && !a.isParent) ? '[A]' : '')}</Text>
                                        {/* {(canDeleteFiles || (!canDeleteFiles && getHistoryName(history) === 'publishMaterial')) && status && status.name !== 'canceled' && showParentFileChangeIcon(getHistoryName(history)) && a.isParent && openGenericModal && <FontIcon iconName={"ChangeEntitlements"} title={"Replace file"} style={{cursor: "pointer", color: '#1E20C2', marginTop: 2 }} onClick={() => {openGenericModal({id: 'uploadDocuments', contextItem: {files: [], fileForm: getEmptyParentFileForm(a.fileName)}, optional: {replaceId: a.id}})}}/>  } */}
                                        {a.isParent && 
                                            <IconButton
                                                menuProps={menuButtonProps}
                                                iconProps={{iconName: 'Download'}}
                                                title="Download"
                                                ariaLabel="Download"
                                                style={{cursor: "pointer", color: 'green', marginTop: 2, height: 5 }}
                                            />                                               
                                        }                                     
                                        {!a.isParent && <FontIcon iconName={"Download"} title={t("Download file")} style={{cursor: "pointer", color: 'green', marginTop: 2 }}  onClick={() => {downloadOrShowFile({id: a.id, fileName: a.fileName})}}/>}
                                    </div>
                                </div>    
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    email: getEmail,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        openGenericModal: ModalCommandActions.openModal,
        downloadOrShowFile: DocumentsCommandActions.downloadOrShowFile
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Files)));