import * as React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { getClassNames } from './index.classNames';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DocumentActions} from '../../redux/actions/ui';
import { IconButton, IIconProps} from '@fluentui/react';
import { getDrawerOpened } from '../../redux/reducers/ui';
import ProjectSelector from '../projectSelector/index';

export interface  LogoProps { drawerOpened: boolean, setDrawerOpened: typeof DocumentActions.setDrawerOpened }

class Logo extends React.Component<LogoProps> {
  constructor(props: LogoProps) {
    super(props);   
  }

  openCloseDrawer = () => {
    const { drawerOpened, setDrawerOpened } = this.props;
    setDrawerOpened({open: !drawerOpened});
  }

  render() {
    const styles = getClassNames();
    const icon: IIconProps = { iconName: 'GlobalNavButton' };
    return (
        <div className={styles.logo}>
           <IconButton iconProps={icon} onClick={this.openCloseDrawer} styles={{root: styles.root, icon: styles.questionMark, rootHovered: styles.rootHovered, rootPressed: styles.rootHovered}} title="Open Navigation Menu" ariaLabel="Open Navigation Menu" />
            <Text style={{marginLeft: 20, color: 'white', fontSize: 16}} variant='xLarge' nowrap>Exod</Text>
            <div style={{marginLeft: 15, marginTop: 2}}><ProjectSelector/></div>
        </div>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  drawerOpened: getDrawerOpened
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ setDrawerOpened: DocumentActions.setDrawerOpened }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)((Logo));