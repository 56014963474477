import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import { DefaultButton} from '@fluentui/react/lib/Button';
import { Text } from '@fluentui/react/lib/Text';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { IContextualMenuItem, IContextualMenuProps, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { Panel, PanelType, IPanelProps } from '@fluentui/react/lib/Panel';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import ScrollablePaneHistory from '../../../components/scrollablePaneHistory/index';
import Notification from '../../../containers/notification/index';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getNotification } from '../../../redux/selectors/notification';
import { getLoader } from '../../../redux/selectors/loader';
import _ from 'lodash';
import {Event} from '../../../models/event';
import {Document, OtherRevision} from '../../../models/document';
import Details from './content/details';
import Files from './content/files';
import Comments from './content/comments';
import Titles from './content/titles';
import OtherRevisions from './content/otherRevisions';
import PublishedTo from './content/publishedTo';
import { getDocument, getOpenModal, getOtherRevisions } from '../../../redux/reducers/document';
import { CommandActions } from '../../../redux/actions/document';
import { getStatusValues } from '../../../redux/reducers/sharedData';
import { Status } from '../../../models/status';
import { getStatusSubMenuProps } from '../../../utils/statusFunctions';
import { getCanChangeStatus, getCanEdit, getCanRead, getCanDeleteFiles, getCanUploadAttachments, getCanUploadFileComments } from '../../../utils/permissionFunctions';
import { CommandActions as ModalCommandActions } from '../../../redux/actions/modal';
import { CommandActions as DocumentsCommandActions } from '../../../redux/actions/documents';
import { ExternalRoleActionsType, InternalRoleActionsType, isExternalApp } from '../../../utils/costants';
import { getHistoryName } from '../../../utils/functions';
import { getGenericModal } from '../../../redux/selectors/ui';
import { getSelectedProject } from '../../../redux/selectors/project';
import { Project } from '../../../models/project';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../../redux/reducers/project';

export interface ModalProps extends RouteComponentProps, WithTranslation {
    openModal: boolean,
    showNotification: boolean, 
    type: MessageBarType, 
    message: string, 
    loading: boolean,
    loadingUi: boolean,
    document?: Document,
    otherRevisions: OtherRevision[],
    statusValues: Status[],
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    selectedProject?: Project,
    darkModeEnabled: boolean,
    resetAndCloseModal: typeof CommandActions.resetAndCloseModal,
    openGenericModal: typeof ModalCommandActions.openModal,
    openEditModal: typeof DocumentsCommandActions.openEditModal,
}

class ModalDocument extends React.Component<ModalProps & RouteComponentProps> {
    closeModal = (e?: any) => {
        const { resetAndCloseModal } = this.props;
        if(e === true || e.type === 'click')
            resetAndCloseModal();
    }

    onRenderNavigationContent: IRenderFunction<IPanelProps> = ((props, defaultRender) => {
        const { document } = this.props;
       
        return (      
        <>    
            <div style={{display: 'flex', marginLeft: 10, marginTop: 24}}>
                <Text variant='xLarge' nowrap styles={{root: {marginTop: 9, marginLeft: 5}}}>{(document) ? (document.documentNumber + ' - ' + document.id) : 'Document'}</Text>
            </div> 
            {// This custom navigation still renders the close button (defaultRender).
            // If you don't use defaultRender, be sure to provide some other way to close the panel.
            defaultRender!(props)}
        </>
        )
    });
  
    onRenderFooterContent = () => {
        const { loading, loadingUi, statusValues, userProjectActions, history, document, selectedProject, t, openGenericModal, openEditModal} = this.props;
        const historyName = getHistoryName(history);
        let canChangeStatus: boolean = getCanChangeStatus(historyName, userProjectActions);
        let statusSubMenuProps: IContextualMenuProps | undefined = (canChangeStatus && document && document.status && selectedProject) ? getStatusSubMenuProps(document.status, statusValues, selectedProject, historyName, openGenericModal) : undefined;
        let canEdit: boolean = getCanEdit(historyName, userProjectActions);
        return (
            <div style={{display: 'flex', gap: 15}}>
                    <>
                        { document && document.isCurrent && historyName === 'reservedNumbers' &&
                            <DefaultButton
                                primary
                                text={t("Select language")} 
                                disabled={loading || loadingUi}
                                iconProps={{iconName: 'DocumentManagement'}}
                                onClick={() => {openGenericModal({id: 'selectLanguage', contextItem: document.languageShort})}}
                            />
                        }
                         { document && document.isCurrent && historyName === 'reservedNumbers' &&
                            <DefaultButton
                                primary
                                text={t("Select file type")} 
                                disabled={loading || loadingUi}
                                iconProps={{iconName: 'PlainText'}}
                                onClick={() => {openGenericModal({id: 'selectFileType', contextItem: document.fileType})}}
                            />
                        }
                        { isExternalApp && document && document.isCurrent && canEdit && document.status &&
                            <DefaultButton
                                primary
                                text={t("Edit")} 
                                disabled={loading || loadingUi}
                                iconProps={{iconName: 'Edit'}}
                                onClick={() => {openEditModal()}}
                            />
                        }
                        { document && document.isCurrent && canChangeStatus &&
                            <DefaultButton
                                primary
                                split 
                                text={t("Change Status")} 
                                menuProps={(statusSubMenuProps) ? statusSubMenuProps : undefined}
                                disabled={loading  || loadingUi || !statusSubMenuProps|| statusSubMenuProps.items.length === 0}
                                iconProps={{iconName: 'Sync'}}
                            />       
                        }
                    </>
                <DefaultButton disabled={loading} onClick={() => this.closeModal(true)} text="Close" />
            </div>
        );
    };

    renderTitlesSections = (): boolean => {
        const {document} = this.props;
        return (document && (document.titleLine1En || document.titleLine1Pl || document.titleLine2En || document.titleLine2Pl || document.titleLine3En || 
                             document.titleLine3Pl || document.titleLine4En || document.titleLine4Pl || document.titleLine5En || document.titleLine5Pl || 
                             document.titleLine6En || document.titleLine6Pl)) 
        ? true : false;
    }

    
    render() {
        const styles = getClassNames();
        const { openModal, showNotification, message, type, loading, document, statusValues, userProjectActions, history, otherRevisions, loadingUi, darkModeEnabled, t } = this.props; 
        let canRead: boolean = getCanRead(getHistoryName(history), userProjectActions);
        let canDeleteFiles: boolean = getCanDeleteFiles(getHistoryName(history), userProjectActions);
        let canUplaodAttachments: boolean = getCanUploadAttachments(getHistoryName(history), userProjectActions);
        let canUploadFileComments: boolean = getCanUploadFileComments(getHistoryName(history), userProjectActions);
        const events: Event[] = (canRead && document && document.statusHistory.length > 0) ? _.orderBy(_.map(document.statusHistory, sh => {return new Event(sh, statusValues)}), s => s.date) : [];
        const historyName = getHistoryName(history);
        return openModal && document && (
            <Panel
              isOpen={openModal}
              onDismiss={(e: any) => this.closeModal(e)} 
              hasCloseButton={!loading}
              type={PanelType.large}
              closeButtonAriaLabel="Close"
              // headerText={title}
              styles={{navigation: styles.header, content: styles.content, scrollableContent: styles.scrollableContent, footer: styles.footer, contentInner: styles.contentInner, closeButton: {height: 40} }}
              onRenderNavigationContent={this.onRenderNavigationContent}
              onRenderFooterContent={this.onRenderFooterContent}
            >
              <div className={styles.notify}>
                <Notification name={'document'} show={showNotification} type={type} message={message}/>
              </div>
             
              <ProgressIndicator progressHidden={ !loading }/>
              {!loadingUi &&
                <ScrollablePaneHistory statusHistory={events} className={styles.notifyHeader} darkModeEnabled={darkModeEnabled} items={[ 
                    { text: t("Details"), body: <div className={styles.tabContainer}><Details document={document}/></div> },
                    ...((this.renderTitlesSections()) ? [ { text: t("Titles"), body: <div className={styles.tabContainer}><Titles document={document}/></div> } ]: []), 
                    ...((document.files.length > 0 || (document.files.length === 0 && (canUplaodAttachments || canUploadFileComments))) ? [ { text: t("Files"), body: <div className={styles.tabContainer}><Files documentId={document.id as number} clientNumber={document.clientNumber} isCurrent={document.isCurrent ? true : false} customerDocNumber={document.customerDocNumber} customerDocIndex={document.customerDocIndex} projectDocumentNumber={document.projectDocumentNumber} files={document.files} status={document.status as Status} canDeleteFiles={canDeleteFiles} canUplaodAttachments={canUplaodAttachments} canUploadFileComments={canUploadFileComments}/></div> } ]: []),       
                    { text: t("Comments"), body: <div className={styles.tabContainer}><Comments document={document} userProjectActions={userProjectActions} status={document.status as Status}/></div> },
                    ...(historyName === 'dashboard' && otherRevisions.length > 0 ? [ { text: t("Other Revisions"), body: <div className={styles.tabContainer}><OtherRevisions otherRevisions={otherRevisions}/></div> } ]: []),   
                    ...(!_.some(userProjectActions, a => a === 'FilterDocumentsByCustomerId') && document.publishedTo.length > 0 ? [ { text: t("Published to"), body: <div className={styles.tabContainer}><PublishedTo publishedTo={document.publishedTo}/></div> } ]: []),   
                ]} />
              }
              {loadingUi && <Spinner size={SpinnerSize.large}/>}
            </Panel>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('document')(state).loading,
    loadingUi: (state) => getLoader('document')(state).loadingUi,
    showNotification: (state) => getNotification('document')(state).show,
	type: (state) => getNotification('document')(state).type,
    message: (state) => getNotification('document')(state).message,
    openModal: getOpenModal,
    document: getDocument,
    otherRevisions: getOtherRevisions,
    statusValues: getStatusValues,
    selectedProject: getSelectedProject,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        resetAndCloseModal: CommandActions.resetAndCloseModal,
        openGenericModal: ModalCommandActions.openModal,
        openEditModal: DocumentsCommandActions.openEditModal,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(ModalDocument)));
