import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { FontIcon, FontSizes, IDropdownOption, TextField } from '@fluentui/react';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import { getClassNames } from './index.classNames';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface DownloadFilesProps extends RouteComponentProps, WithTranslation {
    fileName?: string,
    setOptional: typeof ModalDocumentAction.setOptional
}
class DownloadFiles extends React.Component<DownloadFilesProps> {
    constructor(props: DownloadFilesProps) {
        super(props);   
        this.state = {

        }
    }

    _onRenderCategoryOption = (option?: IDropdownOption): JSX.Element => {
        const styles = getClassNames();
        return (
            <div key={(option) ? option.key : undefined} className={styles.dropDownItem}>
                {option && (option as any).icon && <FontIcon style={{marginRight: 10, fontSize: FontSizes.xLarge, color: (option) ? (option as any).color : undefined}} iconName={(option) ? (option as any).icon : undefined} />}
                <span>{(option) ? option.text : undefined}</span>
            </div>     
        );
    };

    _onRenderCategoryTitle = (options?: IDropdownOption[]): JSX.Element => {
        const option = (options && options.length > 0) ? options[0] : undefined;
        return this._onRenderCategoryOption(option);
    };

    render(){
        const { fileName, setOptional, t } = this.props;
        return (
            <>        
                <TextField 
                    label={t('File name')} 
                    autoComplete={'off'} 
                    value={fileName || ''} 
                    onChange={(e, value) => { 
                        setOptional({id: 'excelReport', optional: value})
                    }}
                    styles={{root: {minWidth: 260}}}
                />                                                                               
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    fileName: (state) => (getGenericModal('excelReport')(state)) ? (getGenericModal('excelReport')(state) as any).optional as string : undefined,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setOptional: ModalDocumentAction.setOptional
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(DownloadFiles)));