import { mergeStyleSets } from '@fluentui/react';
import { FontWeights } from '@fluentui/react';

export interface IComponentClassNames {
    tabContainer: string
    contentContainer: string
    footerContainer: string
    container: string
    button: string
    title: string
    header: string,
    content: string,
    scrollableContent: string,
    footer: string,
    contentInner: string,
    notifyHeader: string,
    notify: string,
    titleText: string,
    descriptionText: string,
    helpText: string,
    wrapper: string
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        container:{
            padding: 10
        },
        tabContainer: {
            marginBottom: 60
        },
        contentContainer: {
            margin: 10
        },
        footerContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 40
        },
        button: {
            marginLeft: 10
        },
        title:{
            fontWeight: 'bold'
        },
        header:{
            justifyContent: 'space-between',
            alignItems: 'center', 
            paddingLeft: 15,
            //borderLeft: '9px solid ' + projectExecutionColorCode,
            height: 80
        },
        content:{
           paddingBottom: 0,
           display: 'flex',
           flexDirection: 'column',
           flexGrow: 1,
        },
        scrollableContent:{
            flexGrow: 1,
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column'
        },
        footer:{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 30
        },
        contentInner:{
            //borderLeft: '9px solid ' + projectExecutionColorCode
        },
        notifyHeader:{
            overflow: "auto",
            position: "relative",
            height: 'calc(100%)'
        },
        notify:{
            paddingLeft: 30,
            paddingBottom: 10
        },
        titleText: {
            color: '#025F52',
            fontWeight: FontWeights.semibold
        },
        descriptionText: {
            color: '#333333',
            fontWeight: FontWeights.regular
        },
        helpText: {
            color: '#333333',
            fontWeight: FontWeights.regular
        },
        wrapper: {
            height: 360,
            position: 'relative',
        },
    });
};