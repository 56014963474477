import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { Dropdown, IDropdownOption, Text } from '@fluentui/react';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import { languages } from '../../../translations';
import { withTranslation, WithTranslation  } from 'react-i18next';
import i18n from '../../../i18n';
import { getClassNames } from '../index.classNames';
import { getClassNames as getClassNamesLanguage} from '../../login/languageSelector.classNames';
import { getTheme } from '../../../utils/costants';
import { getContextualDocuments } from '../../../redux/selectors/documents';
import { Document } from '../../../models/document';
import { t } from 'i18next';
import Scrollbars from 'react-custom-scrollbars-2';

export interface SelectLanguageProps extends RouteComponentProps, WithTranslation {
    language?: string,
    isNewLanguage: boolean,
    selectedDocuments: Document[],
    setContextItem: typeof ModalDocumentAction.setContextItem
}
class SelectLanguage extends React.Component<SelectLanguageProps> {
    constructor(props: SelectLanguageProps) {
        super(props);   
    }

    _onRenderFlagOption = (option?: IDropdownOption): JSX.Element => {
        const styles = getClassNamesLanguage(getTheme(false));
        return (
            <div key={(option) ? option.key : undefined} className={styles.dropDownItem}>
                {option && (option as any).icon}
                <span>{(option) ? option.text : undefined}</span>
            </div>     
        );
    };

    _onRenderFlagTitle = (options?: IDropdownOption[]): JSX.Element => {
        const option = (options && options.length > 0) ? options[0] : undefined;
        return this._onRenderFlagOption(option);
    };
    render(){
        const { language, selectedDocuments, setContextItem, isNewLanguage } = this.props;
        const styles = getClassNamesLanguage(getTheme(false));
        let language_options: {key: string, text: string}[] = _.map(languages, l => {return { key: l.code, text: l.description }});
        return (
            <>
                {!isNewLanguage && <Text variant='mediumPlus' nowrap>{t('You are going to change the language of the following ' + ((selectedDocuments.length === 1) ? 'document:' : 'documents:')).toString()}</Text>}
                {isNewLanguage  && <Text variant='mediumPlus' nowrap>{t('You are going to add a new language for the following ' + ((selectedDocuments.length === 1) ? 'document:' : 'documents:')).toString()}</Text>}
                <Scrollbars style={{ marginTop: 10, marginBottom: 20}} autoHeight autoHeightMax={420}>
                    {_.map(selectedDocuments, (sd, index) => {
                        return <div key={index}><Text nowrap>{sd.documentNumber}</Text></div>
                    })}
                </Scrollbars>                                   
                <Dropdown
                    label={'Language'}
                    placeholder='Select a language'
                    options={language_options}
                    onChange={(e, value) => { setContextItem({id: 'selectLanguage', item: value ? value.key as string : undefined}) }}
                    selectedKey={(language) ? language : null}     
                    styles={{ dropdownItemsWrapper: {maxHeight: 360} }}
                />                                                   
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    language: (state) => (getGenericModal('selectLanguage')(state)) ? (getGenericModal('selectLanguage')(state) as any).contextItem as string : undefined,
    isNewLanguage: (state) => (getGenericModal('selectLanguage')(state)) ? (getGenericModal('selectLanguage')(state) as any).optional as boolean : false,
    selectedDocuments: getContextualDocuments
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItem: ModalDocumentAction.setContextItem
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(SelectLanguage)));