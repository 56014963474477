import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { getClassNames } from './index.classNames';
import { DocumentActions as UiDocumentActions} from '../../../redux/actions/ui';
import { CommandActions as DocumentCommandActions} from '../../../redux/actions/document';
import { CommandActions as DocumentsCommandActions} from '../../../redux/actions/documents';
import { CommandActions as ModalCommandActions} from '../../../redux/actions/modal';
import _ from 'lodash'
import { getFilterPanelOpened } from '../../../redux/reducers/ui';
import { Document, getEmptyFileForm } from '../../../models/document';
import { getTotal } from '../../../redux/reducers/documents';
import { getStatusValues } from '../../../redux/reducers/sharedData';
import { Status } from '../../../models/status';
import { getStatusSubMenuItems } from '../../../utils/statusFunctions';
import { IContextualMenuItem } from '@fluentui/react';
import { InternalRoleActionsType, ExternalRoleActionsType, isExternalApp } from '../../../utils/costants';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getContextualDocuments, getSelectedDocument } from '../../../redux/selectors/documents';
import { getSelectedProject, getSelectedProjectCodeword } from '../../../redux/selectors/project';
import { Project } from '../../../models/project';
import { formatDateFile, getHistoryName } from '../../../utils/functions';
import { SharedDataCommandActions } from '../../../redux/actions/sharedData';
import { getSelectedProjectCustomerStakeholders } from '../../../redux/selectors/administration';
import { StakeHolder } from '../../../models/stakeHolder';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface ContactsProps extends RouteComponentProps, WithTranslation  {
    filterPanelOpened: boolean, 
    documents: Document[],
    total: number,
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    statusValues: Status[],
    selectedDocument?: Document,
    selectedProject?: Project,
    customerStakeholders: StakeHolder[],
    codeWord?: string,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]
    setFilterPanelOpened: typeof UiDocumentActions.setFilterPanelOpened,
    openEditModal: typeof DocumentsCommandActions.openEditModal,
    setOpenModal: typeof DocumentCommandActions.setOpenModal,
    openGenericModal: typeof ModalCommandActions.openModal,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    fetchReserveNumbersValues: typeof SharedDataCommandActions.fetchReserveNumbersValues,
}

class DocumentsCommandBar extends React.Component<ContactsProps & RouteComponentProps> {
    constructor(props: ContactsProps) {
        super(props);
    }   


    getButtons = () => {
        const { documents, total, openGenericModal, openEditModal, fetchDocuments, fetchReserveNumbersValues, loadingContent, loading, loadingScroll, statusValues, userProjectActions, selectedDocument, selectedProject, history, customerStakeholders, codeWord, t } = this.props;
        const selectedDocumentIds = _.map(documents, d => d.id as number);
        let buttons: ICommandBarItemProps[] = [];
        let historyName = getHistoryName(history);
        const singleCustomer = (customerStakeholders.length === 1) ? customerStakeholders[0] : undefined;
        let statusSubMenuItems: IContextualMenuItem[] = (selectedProject && selectedDocument && selectedDocument.status) ? (getStatusSubMenuItems(selectedDocument.status, statusValues, selectedProject, historyName, openGenericModal, singleCustomer) as IContextualMenuItem[]) : [];
        const commentsExist = (documents && documents.length > 0) ? _.some(documents, d => d.comments && d.comments.length > 0) : true;
        
        buttons = [
             ...(_.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [{
                key: 'reserveNumber',
                name: t('Reserve'),
                disabled: (loadingContent || loading || loadingScroll),
                onClick: () => { fetchReserveNumbersValues() },
                iconProps: {
                    iconName: 'NumberField'
                }
            }] : []),    
            ...(_.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [{
                key: 'newRevision',
                name: t('New Revision'),
                disabled: (selectedDocumentIds.length !== 1 || (selectedDocument && !selectedDocument.isCurrent) || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'revision' }) },
                iconProps: {
                    iconName: 'OpenInNewTab'
                }
            }] : []),    
            ...(_.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [{
                key: 'newSheet',
                name: t('New Sheet'),
                disabled: (selectedDocumentIds.length !== 1 || (selectedDocument && !selectedDocument.isCurrent) || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'sheet', contextItem: 1 }) },
                iconProps: {
                    iconName: 'Document'
                }
            }] : []),    
            ...(_.find(userProjectActions, a => a === 'ChangeStatusDashboard') ? [{
                key: 'changeStatus',
                name: t('Change Status'),
                disabled: (selectedDocumentIds.length !== 1 || (selectedDocument && !selectedDocument.isCurrent) || statusSubMenuItems.length === 0 || loadingContent || loading || loadingScroll),
                iconProps: {
                    iconName: 'Sync'
                },
                subMenuProps: {
                    items: (statusSubMenuItems.length > 0) ? statusSubMenuItems : []
                } 
            }] : []),                    
            // ...(_.find(userProjectActions, a => a === 'EditDashboard') ? [{
            //     key: 'editDocument',
            //     name: t('Edit'),
            //     disabled: (selectedDocumentIds.length !== 1 || (selectedDocument && !selectedDocument.isCurrent) || (selectedDocument && selectedDocument.status && selectedDocument.status.name === 'canceled') || loadingContent || loading || loadingScroll),
            //     onClick: () => {openEditModal()},
            //     iconProps: {
            //         iconName: 'Edit'
            //     },
            // }] : []),  
            // ...(_.find(userProjectActions, a => a === 'DeleteDashboard') ? [{
            //     key: 'delete',
            //     name: t('Delete'),
            //     disabled: (selectedDocumentIds.length === 0 || !(_.every(documents, d => d.isCurrent && d.status && (d.status.name === 'uploaded'))) || loadingContent || loading || loadingScroll),
            //     onClick: () => {openGenericModal({id: 'deleteDocument'})},
            //     iconProps: {
            //         iconName: 'Cancel',
            //         style: {color: "red"}
            //     },
            // }] : []),         
            ...(_.find(userProjectActions, a => (a === 'UploadAttachmentDashboard' || a === 'UploadCommentFileDashboard') && !_.some(userProjectActions, a => a === 'ReserveNumbersDashboard')) ? [{
                key: 'upload',
                name: t('Upload'),
                disabled: (selectedDocumentIds.length === 0 || (_.some(documents, (d: Document) => !d.isCurrent)) || (_.some(documents, (d: Document) => d.status && d.status.name === 'canceled')) || loadingContent || loading || loadingScroll),
                onClick: () => {openGenericModal({id: 'uploadDocuments', contextItem: {files: [], fileForm: getEmptyFileForm((_.find(userProjectActions, a => a === 'UploadAttachmentDashboard')) ? true : false, (_.find(userProjectActions, a => a === 'UploadCommentFileDashboard')) ? true : false)}})},
                iconProps: {
                    iconName: 'BulkUpload'
                },
            }] : []),   
            ...(_.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [{
                key: 'upload',
                name: t('Upload'),
                disabled: (loadingContent || loading || loadingScroll),
                iconProps: {
                    iconName: 'BulkUpload'
                },
                subMenuProps: {
                    items: [
                        {
                            key: 'upload',
                            name: t('Attachments and Comments'),
                            disabled: (selectedDocumentIds.length !== 1 || !(documents[0].isCurrent && documents[0].status && documents[0].status.name !== 'canceled') || (!documents[0].files || documents[0].files.length === 0 || !_.some(documents[0].files, f => f.isParent)) || loadingContent || loading || loadingScroll),
                            onClick: () => {openGenericModal({id: 'uploadDocuments', contextItem: {files: [], fileForm: getEmptyFileForm((_.find(userProjectActions, a => a === 'UploadAttachmentDashboard')) ? true : false, (_.find(userProjectActions, a => a === 'UploadCommentFileDashboard')) ? true : false)}})},
                            iconProps: {
                                iconName: 'Attach'
                            },
                        },
                        {
                            key: 'import',
                            name: 'Zip',
                            disabled: (total === 0 || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'importZip'}) },
                            iconProps: {
                                iconName: 'ZipFolder',
                                style: {color: "#C2891E"}
                            },
                        },
                    ]
                } 
            }] : []),   
            ...(_.find(userProjectActions, a => a === 'ReadDashboard') ? [{
                key: 'downlaod',
                name: t('Download'),
                disabled: (loadingContent || loading || loadingScroll),
                iconProps: {
                    iconName: 'Download',
                },
                subMenuProps: {
                    items: [
                        {
                            key: 'downlaodFiles',
                            name: t('Files'),
                            disabled: (total === 0 || !_.some(documents, d => d.files.length > 0) || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'fileName', contextItem: {fileName: codeWord + '_Documents_' + formatDateFile(new Date()), isParent: false}}) },
                            iconProps:  {
                                iconName: 'DownloadDocument'
                            } 
                        },
                        {
                            key: 'downloadComments',
                            name: t('Comments'),
                            disabled: (total === 0 || !commentsExist || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'comments', optional: codeWord + '_Comments_' + formatDateFile(new Date())}) },
                            iconProps: {
                                iconName: 'Comment',
                            },
                        }
                    ]
                }          
            }] : []),            
            {
                key: 'excelReport',
                name: t('Report'),
                disabled: (total === 0 || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'excelReport', optional: codeWord + '_Document report_' + formatDateFile(new Date())}) },
                iconProps: {
                    iconName: 'ExcelDocument',
                    style: {color: "#008000"}
                  },
            },
            {
                key: 'refresh',
                name: t('Refresh'),
                disabled: (loadingContent || loading || loadingScroll),
                onClick: () => { fetchDocuments({}) },
                iconProps: {
                    iconName: 'Refresh',
                },
            }   
        ]
        return buttons;
    };

    getFarButtons = () => {
      const {setFilterPanelOpened, filterPanelOpened} = this.props;
      return [
     
        {
          key: 'openPanel',
          name: 'Filters',
          iconProps: {
            iconName: 'Filter'
          },
          ariaLabel: 'Filters',    
          onClick: () => {setFilterPanelOpened({open: !filterPanelOpened})}      
        },          
      ]; 
  };

    render() {
        const styles = getClassNames();
        return (
            <>
                <CommandBar
                    styles={{root: styles.root, secondarySet: {borderTop: 1}}}
                    items={this.getButtons()}
                    farItems={this.getFarButtons()}
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    filterPanelOpened: getFilterPanelOpened,
    documents: getContextualDocuments,
    total: getTotal,
    statusValues: getStatusValues,
    selectedDocument: getSelectedDocument,
    selectedProject: getSelectedProject,
    customerStakeholders: getSelectedProjectCustomerStakeholders,
    codeWord: getSelectedProjectCodeword
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setFilterPanelOpened: UiDocumentActions.setFilterPanelOpened,
        setOpenModal: DocumentCommandActions.setOpenModal,
        openGenericModal: ModalCommandActions.openModal,
        openEditModal: DocumentsCommandActions.openEditModal,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        fetchReserveNumbersValues: SharedDataCommandActions.fetchReserveNumbersValues,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(DocumentsCommandBar)));
