import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {CommandActions} from '../../../redux/actions/documents';
import Dropzone from 'react-dropzone'
import _, { size } from 'lodash';
import { ProgressIndicator } from '@fluentui/react';
import { ModalCommandActions } from '../../../redux/actions/modal';
import { getDocumentsToUpload, getDocumentsToUploadTotal } from '../../../redux/reducers/documents';
import { getFileExtension } from '../../../utils/functions';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getClassNames } from './index.classNames';

export interface ImportZipProps extends RouteComponentProps, WithTranslation {
    loadingContent: boolean,
    documentsToUploadTotal: number,
    documentsToUpload: number,
    isPartnerFile?: boolean,
    importZip: typeof CommandActions.importZip,
    openGenericModal: typeof ModalCommandActions.openModal
}

export interface ImportZipState {

}
class ImportZip extends React.Component<ImportZipProps, ImportZipState> {
    constructor(props: ImportZipProps) {
        super(props);   
        this.state = {

        }
    }
    
    onDrop = (accepted: any, rejected: any) => {
        const {isPartnerFile, t, importZip, openGenericModal} = this.props;
        if(accepted.length === 1){
            const file = accepted[0];
            if(file.size > 0 && getFileExtension(file.name) === 'zip')
                importZip({file, isPartnerFile});
            else if(file.size === 0)
                openGenericModal({id: 'warning', contextItem: t('The file you uploaded is empty')});
            else    
                openGenericModal({id: 'warning', contextItem:  t('Please select a .zip file')});
        }
        else if(accepted.length > 1)
            openGenericModal({id: 'warning', contextItem:  t('Only one file can be uploaded')});
        else
            openGenericModal({id: 'warning', contextItem:  t('Please select a .zip file')});
    }

    render(){
        const { loadingContent, documentsToUploadTotal, documentsToUpload, t } = this.props;
        const currentDoc = (documentsToUpload > 0) ? (documentsToUploadTotal - documentsToUpload + 1) : undefined
        const styles = getClassNames();
        return (
            <div>
                <ProgressIndicator description={(currentDoc) ? (currentDoc + " of " + documentsToUploadTotal) : undefined} styles={{root: {height: 35, marginBottom: 10}}} progressHidden={ !loadingContent } percentComplete={(currentDoc) ? ((currentDoc) / documentsToUploadTotal) : 0}/> 
                <Dropzone
                 accept={{
                            "application/zip": [".zip"]
                        }}
                    onDrop={this.onDrop}
                    disabled={loadingContent}
                    noDragEventsBubbling={true}
                >
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className={styles.containerDropzone}>
                            <input {...getInputProps()} />
                           <div style={{padding: 5}}>Drop the .zip file here, or click to select file to upload.</div>
                        </div>
                    )}
                </Dropzone> 
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    documentsToUpload: getDocumentsToUpload,
    documentsToUploadTotal: getDocumentsToUploadTotal,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        importZip: CommandActions.importZip,
        openGenericModal: ModalCommandActions.openModal
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(ImportZip)));