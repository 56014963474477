import React from 'react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { getId } from '@fluentui/react/lib/Utilities';
import { DocumentActions } from '../../../../redux/actions/administrationModal';
import _ from 'lodash';
import { StakeHolder } from '../../../../models/stakeHolder';
import { FontSizes } from '@fluentui/react';
import { getParentFileForm } from '../../../../models/document';
import { CommandActions as ModalCommandActions } from '../../../../redux/actions/modal';

const _hostId: string = getId('tooltipHost');

export const getColumns = (openGenericModal: typeof ModalCommandActions.openModal, theme: any) => {


      const columns: IColumn[] = [
        {
          key: 'edit',
          name: 'Edit',
          fieldName: 'edit',
          minWidth: 20,
          maxWidth: 20,
          isResizable: false,
          isIconOnly: true,
          //onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'number',
          isPadded: false,
          onRender: (item: StakeHolder) => {
            if(item && item.stakeHolderId){
              return (
                <FontIcon iconName={'Edit'} title={'Edit Stakeholder'} onClick={() => {openGenericModal({id: 'newStakeHolder', contextItem: new StakeHolder(item)})}} style={{fontSize: FontSizes.xLarge, color: theme.palette.themePrimary, cursor: 'pointer'}} ></FontIcon>
              );              
            }
          }
        },
        {
          key: 'column0',
          name: 'Company',
          fieldName: 'companyName',
          minWidth: 110,
          maxWidth: 110,
          isResizable: true,
          data: 'string',
          isPadded: true,        
        },
        {
          key: 'column1',
          name: 'Address',
          fieldName: 'address',
          minWidth: 140,
          maxWidth: 140,
          isResizable: true,
          data: 'string',
          isPadded: true,        
        },
        {
          key: 'column2',
          name: 'Country',
          fieldName: 'country',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
          data: 'string',
          isPadded: true,        
        },
        {
          key: 'column3',
          name: 'Initials',
          fieldName: 'initials',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
          data: 'string',
          isPadded: true,        
        },
        {
          key: 'column7',
          name: 'Is Active',
          fieldName: 'isActive',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
          data: 'string',
          isPadded: true,     
          onRender: (item: StakeHolder) => {
             return(
               (item.isActive) ? 'Yes' : 'No'
             );
           }   
        },
        {
          key: 'column4',
          name: 'Is Partner',
          fieldName: 'isPartner',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
          data: 'string',
          isPadded: true,     
          onRender: (item: StakeHolder) => {
             return(
               (item.isPartner) ? 'Yes' : 'No'
             );
           }   
        },
        {
          key: 'column5',
          name: 'Is Supplier',
          fieldName: 'isSupplier',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
          data: 'string',
          isPadded: true,     
          onRender: (item: StakeHolder) => {
             return(
               (item.isSupplier) ? 'Yes' : 'No'
             );
           }   
        },
        {
          key: 'column6',
          name: 'Is Customer',
          fieldName: 'isCustomer',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
          data: 'string',
          isPadded: true,     
          onRender: (item: StakeHolder) => {
             return(
               (item.isCustomer) ? 'Yes' : 'No'
             );
           }   
        },
        
    ];

    return columns;
}




