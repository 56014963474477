import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../index.classNames';
import { Label, TextField, Text } from '@fluentui/react';
import _ from 'lodash';
import ScrollablePaneHistory from '../../../../components/scrollablePaneHistory/index';
import { Document } from '../../../../models/document';
import { NumericFormat }from 'react-number-format';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getSelectedProject } from '../../../../redux/selectors/project';
import { Project } from '../../../../models/project';
import { getHistoryName, getPurposeLabel } from '../../../../utils/functions';

export interface DetailsProps extends RouteComponentProps, WithTranslation {
    document: Document,
    project?: Project
}

class Details extends React.Component<DetailsProps & RouteComponentProps> {
    constructor(props: DetailsProps) {
        super(props);   
    }
    render(){
        const { project, document, t, history } = this.props;
        const styles = getClassNames();
        const historyName = getHistoryName(history);
        return (
            <>
                <div className={styles.tabContainer}>           
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap>{t('Details')}</Text></div>    
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10}}>
                        {document.description &&
                            <TextField 
                                readOnly
                                label={t("Description 1")} 
                                autoComplete={'off'} 
                                value={document.description} 
                                title={document.description}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }   
                        {document.description2 &&
                            <TextField 
                                readOnly
                                label={t("Description 2")} 
                                autoComplete={'off'} 
                                value={document.description2} 
                                title={document.description2}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }     
                        {document.projectDocumentNumber &&
                            <TextField 
                                readOnly
                                label={t("Project Doc.Number")} 
                                autoComplete={'off'} 
                                value={document.projectDocumentNumber} 
                                title={document.projectDocumentNumber}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }       
                        {document.d3documentId &&
                            <TextField 
                                readOnly
                                label={t("D3 Doc.Id")} 
                                autoComplete={'off'} 
                                value={document.d3documentId} 
                                title={document.d3documentId}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }                     
                        {document.authoringStatus &&
                            <TextField 
                                readOnly
                                label={t("Authoring status")} 
                                autoComplete={'off'} 
                                value={document.authoringStatus} 
                                title={document.authoringStatus}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.language &&
                            <TextField 
                                readOnly
                                label={t("Language")} 
                                autoComplete={'off'} 
                                value={document.language} 
                                title={document.language}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.documentIndex &&
                            <TextField 
                                readOnly
                                label={t("Document Index")}
                                autoComplete={'off'} 
                                value={document.documentIndex} 
                                title={document.documentIndex}
                                styles={{root: {minWidth: 256}}}
                            />        
                        }
                        {document.supplierDocumentNumber &&
                            <TextField 
                                readOnly
                                label={t("SMS Document number")}
                                autoComplete={'off'} 
                                value={document.documentNumber} 
                                title={document.documentNumber}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.docType &&
                            <TextField 
                                readOnly
                                label={t("Document type")} 
                                autoComplete={'off'} 
                                value={document.docType.code + ' - ' + document.docType.name} 
                                title={document.docType.code + ' - ' + document.docType.name}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.discipline &&
                            <TextField 
                                readOnly
                                label={t("Discipline")} 
                                autoComplete={'off'} 
                                value={document.discipline.code + ' - ' + document.discipline.name} 
                                title={document.discipline.code + ' - ' + document.discipline.name}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.protectionClass &&
                            <TextField 
                                readOnly
                                label={t("Protection class")} 
                                autoComplete={'off'} 
                                value={document.protectionClass} 
                                title={document.protectionClass}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.protectionClass &&
                            <TextField 
                                readOnly
                                label={t("Usage")} 
                                autoComplete={'off'} 
                                value={document.usage} 
                                title={document.usage}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {project &&
                            <TextField 
                                readOnly
                                label={t("Project number")} 
                                autoComplete={'off'} 
                                value={project.projectName} 
                                title={project.projectName}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {project &&
                            <TextField 
                                readOnly
                                label="Project Codeword" 
                                autoComplete={'off'} 
                                value={project.projectCodeword} 
                                title={project.projectCodeword}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.plant &&
                            <TextField 
                                readOnly
                                label={t("Plant")} 
                                autoComplete={'off'} 
                                value={(document.plant.description) ? (document.plant.code + ' - ' + document.plant.description) : document.plant.code} 
                                title={(document.plant.description) ? (document.plant.code + ' - ' + document.plant.description) : document.plant.code} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.plantUnit &&
                            <TextField 
                                readOnly
                                label={t("Plant unit")} 
                                autoComplete={'off'} 
                                value={(document.plantUnit.description) ? (document.plantUnit.code + ' - ' + document.plantUnit.description) : document.plantUnit.code} 
                                title={(document.plantUnit.description) ? (document.plantUnit.code + ' - ' + document.plantUnit.description) : document.plantUnit.code} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.plantSection &&
                            <TextField 
                                readOnly
                                label={t("Plant section")} 
                                autoComplete={'off'} 
                                value={(document.plantSection.description) ? (document.plantSection.code + ' - ' + document.plantSection.description) : document.plantSection.code} 
                                title={(document.plantSection.description) ? (document.plantSection.code + ' - ' + document.plantSection.description) : document.plantSection.code} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.equipment &&
                            <TextField 
                                readOnly
                                label={t("Plant section")} 
                                autoComplete={'off'} 
                                value={(document.equipment.description) ? (document.equipment.code + ' - ' + document.equipment.description) : document.equipment.code} 
                                title={(document.equipment.description) ? (document.equipment.code + ' - ' + document.equipment.description) : document.equipment.code} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.entity &&
                            <TextField 
                                readOnly
                                 label={t("Entity")} 
                                autoComplete={'off'} 
                                value={document.entity.name} 
                                title={document.entity.name}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.sheet &&
                            <div>
                                <Label>{t('Sheet number')}</Label>
                                <NumericFormat
                                    key="sheet"
                                    readOnly
                                    customInput={TextField}
                                    decimalScale={0}
                                    value={(document.sheet) ? document.sheet : ''}
                                    title={(document.sheet) ? document.sheet.toString() as string : ''}
                                    autoComplete={'off'}
                                    styles={{root: {minWidth: 256}}}
                                />   
                            </div>       
                        }
                        {document.purpose &&
                            <TextField 
                                readOnly
                                label={t(getPurposeLabel(historyName))} 
                                autoComplete={'off'} 
                                value={document.purpose.description} 
                                title={document.purpose.description}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.transmittalId &&
                            <TextField 
                                readOnly
                                 label={t("Transmittal Id")} 
                                autoComplete={'off'} 
                                value={document.transmittalId} 
                                title={document.transmittalId}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                         {document.transmittalNumber &&
                            <TextField 
                                readOnly
                                 label={t("Transmittal N°")} 
                                autoComplete={'off'} 
                                value={document.transmittalNumber} 
                                title={document.transmittalNumber}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.responsible &&
                            <TextField 
                                readOnly
                                 label={t("Resposible")} 
                                autoComplete={'off'} 
                                value={document.responsible} 
                                title={document.responsible}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.totalSheet !== undefined && document.totalSheet > 0 &&
                            <div>
                                <Label>{t('Total Sheet')}</Label>
                                <NumericFormat
                                    key="sheet"
                                    readOnly
                                    customInput={TextField}
                                    decimalScale={0}
                                    value={(document.totalSheet) ? document.totalSheet : ''}
                                    autoComplete={'off'}
                                    title={document.totalSheet.toString()}
                                    styles={{root: {minWidth: 256}}}
                                />   
                            </div>
                        }                     
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    project: getSelectedProject
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
     
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Details)));