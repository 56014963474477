import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../index.classNames';
import { Label, TextField, Text } from '@fluentui/react';
import _ from 'lodash';
import ScrollablePaneHistory from '../../../../components/scrollablePaneHistory/index';
import { Document } from '../../../../models/document';
import { NumericFormat }from 'react-number-format';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface DetailsProps extends RouteComponentProps, WithTranslation {
    document: Document
}

class Details extends React.Component<DetailsProps> {
    constructor(props: DetailsProps) {
        super(props);   
    }

    render(){
        const { document, t } = this.props;
        const styles = getClassNames();
        return (
            <>
                <div className={styles.tabContainer}>           
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap>{t('Titles')}</Text></div>    
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10}}>                       
                        {document.titleLine1Pl &&
                            <TextField 
                                readOnly
                                label={t('Customer')} 
                                autoComplete={'off'} 
                                value={document.titleLine1Pl} 
                                title={document.titleLine1Pl}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine1En &&
                            <TextField 
                                readOnly
                                label={t('Titles') + "En"}
                                autoComplete={'off'} 
                                value={document.titleLine1En} 
                                title={document.titleLine1En}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine2Pl &&
                            <TextField 
                                readOnly
                                label={t('Site')} 
                                autoComplete={'off'} 
                                value={document.titleLine2Pl} 
                                title={document.titleLine2Pl}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine2En &&
                            <TextField 
                                readOnly
                                label={t('Site') + "En"} 
                                autoComplete={'off'} 
                                value={document.titleLine2En}  
                                title={document.titleLine2En}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine3Pl &&
                            <TextField 
                                readOnly
                                label={t('Project')} 
                                autoComplete={'off'} 
                                value={document.titleLine3Pl} 
                                title={document.titleLine3Pl}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine3En &&
                            <TextField 
                                readOnly
                                label={t('Project') + "En"} 
                                autoComplete={'off'} 
                                value={document.titleLine3En} 
                                title={document.titleLine3En}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine4Pl &&
                            <TextField 
                                readOnly
                                label={t('Title') + "1"}
                                autoComplete={'off'} 
                                value={document.titleLine4Pl} 
                                title={document.titleLine4Pl}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine4En &&
                            <TextField 
                                readOnly
                                label={t('Title') + "1 En"}
                                autoComplete={'off'} 
                                value={document.titleLine4En}
                                title={document.titleLine4En} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine5Pl &&
                            <TextField 
                                readOnly
                                label={t('Title') + "2"}
                                autoComplete={'off'} 
                                value={document.titleLine5Pl} 
                                title={document.titleLine5Pl}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine5En &&
                            <TextField 
                                readOnly
                                label={t('Title') + "2 En"}
                                autoComplete={'off'} 
                                value={document.titleLine5En} 
                                title={document.titleLine5En}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine6Pl &&
                            <TextField 
                                readOnly
                                label={t('Title') + "3"} 
                                autoComplete={'off'} 
                                value={document.titleLine6Pl} 
                                title={document.titleLine6Pl}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.titleLine6En &&
                            <TextField 
                                readOnly
                                label={t('Title') + "3 En"} 
                                autoComplete={'off'} 
                                value={document.titleLine6En} 
                                title={document.titleLine6En}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({

});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
     
    }, dispatch);
}


export default connect(undefined, matchDispatchToProps)(withTranslation()(withRouter(Details)));