import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from '../index.classNames';
import { Text, Toggle} from '@fluentui/react';
import _ from 'lodash';
import { DocumentActions as ProjectDocumentActions } from '../../../../redux/actions/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getAllProjects, getIsEdit, getProjectFormPurposes } from '../../../../redux/reducers/project';
import { Project } from '../../../../models/project';

export interface BodyProps extends RouteComponentProps { 
    purposes: {purposeId?: number, name?: string, description?: string, isWithApproval: boolean}[],
    allProjects: Project[],
    isEdit: boolean,
    setProjectFormPurposeIsWithApproval: typeof ProjectDocumentActions.setProjectFormPurposeIsWithApproval,
}

class Body extends React.Component<BodyProps> {
    constructor(props: BodyProps) {
        super(props); 
    }

    render(){
        const styles = getClassNames();
        const { purposes, setProjectFormPurposeIsWithApproval } = this.props;
        return ( 
            <>         
                <div className={styles.tabContainer}>    
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap >Purposes</Text></div>   
                    <div style={{display: 'flex', gap: 50}}>
                        <div>
                            <div style={{marginBottom: 15}}><Text variant='mediumPlus' nowrap>Name</Text></div>
                            {_.map(purposes, (p, index) => { return (
                                <div key={index + '_name'}>
                                    <div style={{marginBottom: 9}}>
                                        <Text variant='medium' nowrap>{p.name}</Text>
                                    </div>
                                </div>
                            );})}   
                        </div>
                        <div>
                            <div style={{marginBottom: 15}}><Text variant='mediumPlus' nowrap>Description</Text></div>
                            {_.map(purposes, (p, index) => { return (
                                <div key={index + '_desc'}>
                                    <div style={{marginBottom: 9}}>
                                        <Text variant='medium' nowrap>{p.description}</Text>
                                    </div>
                                </div>
                            );})}   
                        </div>
                        <div>
                            <div style={{marginBottom: 15}}><Text variant='mediumPlus' nowrap>Is with approval</Text></div>
                            {_.map(purposes, (p, index) => { return (
                                <div key={index + '_config'}>
                                    <div>
                                        <Toggle onText="Yes" offText="No" onChange={(e, value) => {setProjectFormPurposeIsWithApproval({purposeId: p.purposeId as number, value: (value) ? true : false})}} checked={p.isWithApproval} />                      
                                    </div>
                                </div>
                            );})}                                        
                        </div>
                    </div>
                </div>                                                                                                                 
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allProjects: getAllProjects,
    isEdit: getIsEdit,
    purposes: getProjectFormPurposes,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        setProjectFormPurposeIsWithApproval: ProjectDocumentActions.setProjectFormPurposeIsWithApproval,
     }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Body));