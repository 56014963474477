import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { DirectionalHint, FontIcon, IRenderFunction, ITextFieldProps, TagItem, TooltipHost, Text, FontSizes, DefaultButton, TextField } from '@fluentui/react';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import { getFolders } from '../../../redux/reducers/sharedData';
import { HierarchyExplorer } from '@pwbapps/hierarchyexplorer';
import { Folder } from '../../../models/folder';
import { getLoader } from '../../../redux/selectors/loader';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface FolderProps extends RouteComponentProps, WithTranslation {
    folder?: Folder,
    loadingContent: boolean,
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty,
    setContextItem: typeof ModalDocumentAction.setContextItem,
}
class Folders extends React.Component<FolderProps> {
    constructor(props: FolderProps) {
        super(props);   
    }



    render(){
        const { folder, loadingContent, t, setContextItemProperty } = this.props;
        return (
            <>  
                {folder && 
                    <> 
                        {<Text>{t('Folder path')}: {folder.description}</Text>}             
                        <TextField 
                            label={t("Folder name")} 
                            autoComplete={'off'} 
                            value={folder.folderName || ''} 
                            onChange={(e, value) => { 
                                setContextItemProperty({id: 'newEditFolder', name: 'folderName', value: (value) ? value : undefined});
                            }}
                            styles={{root: {minWidth: 260, marginTop: 20}}}
                        />              
                    </>
                }     
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    folder: (state) => (getGenericModal('newEditFolder')(state)) ? (getGenericModal('newEditFolder')(state) as any).contextItem : undefined,
    folders: getFolders,
    loadingContent: (state) => getLoader('dashboard')(state).loadingContent,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItem: ModalDocumentAction.setContextItem,
        setContextItemProperty: ModalDocumentAction.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Folders)));