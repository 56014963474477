import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from '../index.classNames';
import { Text, IContextualMenuProps, FontIcon, FontSizes, Link} from '@fluentui/react';
import moment from 'moment';
import _ from 'lodash';
import { ActivityItem, Icon } from '@fluentui/react';
import { Comment, CommentForm } from '../../../../models/comment';
import {Document, FileGrid} from '../../../../models/document';
import { getEmail } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { ExternalRoleActionsType, getTheme, InternalRoleActionsType } from '../../../../utils/costants';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import NewComment from '../functionalities/newComment';
import { CommandActions as ModalCommandActions } from '../../../../redux/actions/modal';
import { CommandActions as DocumentCommandActions } from '../../../../redux/actions/document';
import { getGenericModal } from '../../../../redux/selectors/ui';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getHistoryName } from '../../../../utils/functions';
import { getCanInsertTextComments } from '../../../../utils/permissionFunctions';
import { Status } from '../../../../models/status';
import { CommandActions as DocumentsCommandActions } from '../../../../redux/actions/documents';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../../../redux/reducers/project';
let GenericModal = GenericModalGenerator();
export interface CommentsProps extends RouteComponentProps, WithTranslation { 
    document: Document,
    email?: string,
    commentForm: CommentForm,
    status: Status,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    darkModeEnabled: boolean
    openGenericModal: typeof ModalCommandActions.openModal,
    addComment: typeof DocumentCommandActions.addComment,
    downloadOrShowFile: typeof DocumentsCommandActions.downloadOrShowFile,
}

class Comments extends React.Component<CommentsProps & RouteComponentProps> {
    constructor(props: CommentsProps) {
        super(props); 
    }

    render(){
        const styles = getClassNames();
        const { document, email, commentForm, userProjectActions, history, status, t, openGenericModal, addComment, downloadOrShowFile, darkModeEnabled } = this.props;
        const newCommentForm: CommentForm = new CommentForm();
        const historyName = getHistoryName(history);
        let canWriteComments: boolean = getCanInsertTextComments(historyName, userProjectActions);
        let genericComments: (Comment | FileGrid)[] = _.orderBy([...document.comments, ..._.filter(document.files, f => f.isComment)], c => c.creationDate, 'desc');

        const items = _.map(genericComments, (comment, index) => {  
            const menuProps: IContextualMenuProps = {
                items: [
                  {
                    key: 'remove',
                    text: t('Remove comment'),
                    iconProps: { iconName: 'Delete' },
                  }
                ],
                directionalHintFixed: true
            };
            const item = {
                activityDescription: [
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Text title={t('Comment text')} styles={{root: styles.titleText}} style={{marginRight: 5}}>{comment.createdBy}</Text>
                        <span> {t('commented')}</span>
                    </div>
                ],
                activityIcon: <Icon iconName={'CannedChat'} />,
                comments: [
                    (comment.type === 'comment') ? <Text styles={{root: styles.descriptionText}}>{(comment as Comment).text}</Text> : <Link onClick={() => {downloadOrShowFile({id: (comment as FileGrid).id, fileName: (comment as FileGrid).fileName})}}>{(comment as FileGrid).fileName}</Link>
                ],
                timeStamp: moment(comment.creationDate).format('DD/MM/YYYY HH:mm'),
            }
            return <ActivityItem {...item} key={index + '_aitem'} styles={{root: {marginBottom: 10}}}  />
        });

        return ( 
            <>         
                <div className={styles.tabContainer}>    
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 30}}>
                        <div style={{marginRight: 20}}><Text variant='xLarge' nowrap >{t('Comments')}</Text></div>   
                        { document && document.isCurrent && status && status.name !== 'canceled' && canWriteComments &&
                            <FontIcon 
                                iconName={'Add'} 
                                title={'New comment'} 
                                onClick={() => {openGenericModal({id: 'newComment', contextItem: newCommentForm})}}
                                style={{cursor: 'pointer', fontSize: FontSizes.large, color: getTheme(darkModeEnabled).palette.themePrimary}}>
                            </FontIcon>  
                        }
                    </div>       
                    {items}                                                                            
                </div>

                <GenericModal 
                    id={'newComment'}
                    title={t('New Comment')}
                    message={''}                 
                    onClose={() => {}}
                    onConfirm={() => {addComment({})}}
                    btnConfirmText={t('Save')}
                    btnCloseText={t('Cancel')}
                    disableConfirm={!commentForm || !commentForm.commentText}
                    customComponent={<NewComment commentForm={commentForm}/>}   
                    width={640}
                />                             
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    email: getEmail,
    commentForm: (state) => (getGenericModal('newComment')(state)) ? (getGenericModal('newComment')(state) as any).contextItem : undefined,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        openGenericModal: ModalCommandActions.openModal,
        addComment: DocumentCommandActions.addComment,
        downloadOrShowFile: DocumentsCommandActions.downloadOrShowFile,
     }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Comments)));