import {createAction, ActionsUnion, GridDataConfigActions} from '@pwbapps/reduxcore';
import { MessageBarType } from '@fluentui/react';
import { Document, EditDocumentForm, FileForm, FileGrid } from '../../models/document';

export const FEATURE = '[DOCUMENTS]'

export const FETCH_DOCUMENTS = `[DOCUMENTS] FETCH DOCUMENTS`;
export const GET_DOCUMENTS_BY_ID_AND_UPDATE_GRID = `[DOCUMENTS] GET DOCUMENTS BY ID AND UPDATE GRID`;
export const UPLOAD_DOCUMENTS = `[DOCUMENTS] UPLOAD DOCUMENTS`;
export const UPLOAD_SUPPLIER_FILE = `[DOCUMENTS] UPLOAD SUPPLIER FILE`;
export const UPLOAD_PARTNER_FILE = `[DOCUMENTS] UPLOAD PARTNER FILE`;
export const IMPORT_ZIP = `[DOCUMENTS] IMPORT ZIP`;
export const RESERVE_NUMBER_SUPPLIER = `[DOCUMENTS] RESERVE NUMBER SUPPLIER`;
export const CHANGE_STATUS = `[DOCUMENTS] CHANGE STATUS`;
export const OPEN_EDIT_MODAL = `[DOCUMENTS] OPEN_EDIT_MODAL`;
export const EDIT_DOCUMENT = `[DOCUMENTS] EDIT DOCUMENT`;
export const DELETE_DOCUMENTS = `[DOCUMENTS] DELETE DOCUMENTS`;
export const DELETE_FILE = `[DOCUMENTS] DELETE FILE`;
export const DOWNLOAD_OR_SHOW_FILE = `[DOCUMENTS] DOWNLOAD OR SHOW DOCUMENT`;
export const DOWNLOAD_FILE = `[DOCUMENTS] DOWNLOAD DOCUMENT`;
export const DOWNLOAD_COMMENTS = `[DOCUMENTS] DOWNLOAD COMMENTS`;
export const DOWNLOAD_FILES = `[DOCUMENTS] DOWNLOAD FILES`;
export const DOWNLOAD_TEMPLATE = `[DOCUMENTS] DOWNLOAD TEMPLATE`;
export const DOWNLOAD_EXCEL_REPORT = `[DOCUMENTS] DOWNLOAD EXCEL REPORT`;
export const GET_IDS_AND_DO_SOMETHING = `[DOCUMENTS] GET IDS AND DO SOMETHING`;
export const SET_VALUE = `[DOCUMENTS] SET VALUE`;
export const CHECK_EXISTING_ENG_ORDERS = `[DOCUMENTS] CHECK EXISTING ENG ORDERS`;
export const SET_GRID_OR_MODAL_LOADERS_AND_NOTIFICATION = `[DOCUMENTS] SET GRID OR MODAL LOADERS AND NOTIFICATION`;
export const RESERVE_NUMBERS = `[DOCUMENTS] RESERVE NUMBERS`;
export const NEW_REVISION = `[DOCUMENTS] NEW REVISION`;
export const NEW_SHEET = `[DOCUMENTS] NEW SHEET`;
export const NEW_EDIT_FOLDER= `[DOCUMENTS] NEW EDIT FOLDER`;
export const DELETE_FOLDER= `[DOCUMENTS] DELETE FOLDER`;
export const ADD_OR_REMOVE_DOCUMENTS_TO_FOLDERS= `[DOCUMENTS] ADD OR REMOVE DOCUMENTS TO FOLDERS`;
export const ADD_DOCUMENTS_TO_FOLDERS= `[DOCUMENTS] ADD DOCUMENTS TO FOLDERS`;
export const REMOVE_DOCUMENTS_FROM_FOLDERS= `[DOCUMENTS] REMOVE DOCUMENTS FROM FOLDERS`;
export const SELECT_LANGUAGE = `[DOCUMENTS] SELECT LANGUAGE`;
export const SELECT_FILE_TYPE = `[DOCUMENTS] SELECT FILE TYPE`;
export const NEW_LANGUAGE = `[DOCUMENTS] NEW LANGUAGE`;
export const NEW_FILE_TYPE = `[DOCUMENTS] NEW FILE TYPE`;
export const SAVE_NUMBER_AND_PART = `[DOCUMENTS] SAVE NUMBER AND PART`;

export const DocumentsCommandActions = {
    ...GridDataConfigActions.CommandActions,
    fetchDocuments: ({skip, adding, setDefaultFilters}: {skip?: number, adding?: boolean, setDefaultFilters?: boolean}) => createAction(FETCH_DOCUMENTS, {skip, adding, setDefaultFilters}),
    getDocumentsByIdAndUpdateGrid: ({documentIds}: {documentIds: number[]}) => createAction(GET_DOCUMENTS_BY_ID_AND_UPDATE_GRID, {documentIds}),
    uploadDocuments: ({uploadFileForm, total, failedFiles, documents, statusChanged}: {uploadFileForm?: {fileForm: FileForm, files: any[]}, total?: number, failedFiles?: string[], documents?: Document[], statusChanged?: boolean}) => createAction(UPLOAD_DOCUMENTS, {uploadFileForm, total, failedFiles, documents, statusChanged}),
    uploadSupplierFile: () => createAction(UPLOAD_SUPPLIER_FILE),
    uploadPartnerFile: () => createAction(UPLOAD_PARTNER_FILE),
    importZip: ({file, isPartnerFile}: {file: any, isPartnerFile?: boolean}) => createAction(IMPORT_ZIP, {file, isPartnerFile}),
    reserveNumberSupplier: ({file}: {file: any}) => createAction(RESERVE_NUMBER_SUPPLIER, {file}),
    deleteDocuments: () => createAction(DELETE_DOCUMENTS),
    deleteFile: ({documentId, file, hideUiLoadsAndNots}: {documentId?: number, file?: FileGrid, hideUiLoadsAndNots?: boolean}) => createAction(DELETE_FILE, {documentId, file, hideUiLoadsAndNots}),
    downloadOrShowFile: ({id, fileName, clientNumber}: {id: string, fileName: string, clientNumber?: string}) => createAction(DOWNLOAD_OR_SHOW_FILE, {id, fileName, clientNumber}),
    downloadFile: ({id, fileName, clientNumber}: {id: string, fileName: string, clientNumber?: string}) => createAction(DOWNLOAD_FILE, {id, fileName, clientNumber}),
    editDocument: ({editForm}: {editForm: EditDocumentForm}) => createAction(EDIT_DOCUMENT, {editForm}),
    changeStatus: ({documents}: {documents: Document[]}) => createAction(CHANGE_STATUS, {documents}),
    openEditModal: () => createAction(OPEN_EDIT_MODAL),
    checkExistingEngOrders: ({rows, adding}: {rows: Document[], adding?: boolean}) => createAction(CHECK_EXISTING_ENG_ORDERS, {rows, adding}),
    reserveNumbers: () => createAction(RESERVE_NUMBERS),
    newRevision: () => createAction(NEW_REVISION),
    newSheet: () => createAction(NEW_SHEET),
    selectLanguage: () => createAction(SELECT_LANGUAGE),
    selectFileType: () => createAction(SELECT_FILE_TYPE),
    newLanguage: () => createAction(NEW_LANGUAGE),
    newFileType: () => createAction(NEW_FILE_TYPE),
    saveNumberAndPart: () => createAction(SAVE_NUMBER_AND_PART),
    
    downloadComments: () => createAction(GET_IDS_AND_DO_SOMETHING, undefined, {callback: (documentIds: number[]) => DocumentsEventActions.downloadComments({documentIds})}),
    downloadTemplate: () => createAction(GET_IDS_AND_DO_SOMETHING, undefined, {callback: (documentIds: number[]) => DocumentsEventActions.downloadTemplate({documentIds})}),
    downloadExcelReport: () => createAction(GET_IDS_AND_DO_SOMETHING, undefined, {callback: (documentIds: number[]) => DocumentsEventActions.downloadExcelReport({documentIds})}),
    getIdsAndDoSomething: ({callback}: {callback: (documentIds: number[]) => any}) => createAction(GET_IDS_AND_DO_SOMETHING, undefined, {callback}),

    downloadFiles: () => createAction(DOWNLOAD_FILES),
    setDocumentsToUpload: ({value}: {value: number}) => createAction(SET_VALUE, {name: 'documentsToUpload', value}),
    setDocumentsToUploadTotal: ({value}: {value: number}) => createAction(SET_VALUE, {name: 'documentsToUploadTotal', value}),
    setFetchGuid: ({value}: {value?: string}) => createAction(SET_VALUE, {name: 'fetchGuid', value}),
    setTotal: ({value}: {value: number}) => createAction(SET_VALUE, {name: 'total', value}),
    setSkip: ({value}: {value: number}) => createAction(SET_VALUE, {name: 'skip', value}),

    newEditFolder: () => createAction(NEW_EDIT_FOLDER),
    deleteFolder: () => createAction(DELETE_FOLDER),
    addOrRemoveDocumentsToFolder: ({isAdding}: {isAdding: boolean}) => createAction(ADD_OR_REMOVE_DOCUMENTS_TO_FOLDERS, {isAdding}),
}

export const DocumentsEventActions = {
    setGridOrModalLoadersAndNotification: ({value, message, messageType, closeAutomatically}: {value: boolean, message?: string, messageType?: MessageBarType, closeAutomatically?: boolean}) => createAction(SET_GRID_OR_MODAL_LOADERS_AND_NOTIFICATION, {value, message, messageType, closeAutomatically}),
    downloadComments: ({documentIds}: {documentIds: number[]}) => createAction(DOWNLOAD_COMMENTS, {documentIds}),
    downloadTemplate: ({documentIds}: {documentIds: number[]}) => createAction(DOWNLOAD_TEMPLATE, {documentIds}),
    downloadExcelReport: ({documentIds}: {documentIds: number[]}) => createAction(DOWNLOAD_EXCEL_REPORT, {documentIds}),
    addDocumentsToFolder: () => createAction(ADD_DOCUMENTS_TO_FOLDERS),
    removeDocumentsFromFolder: () => createAction(REMOVE_DOCUMENTS_FROM_FOLDERS)
}
 
export const DocumentsDocumentActions = {
    ...GridDataConfigActions.DocumentActions,
    setValue: ({name, value}: {name: string, value?: any}) => createAction(SET_VALUE, {name, value}),
}

export const CommandActions = {...DocumentsCommandActions};
export const EventActions = {...DocumentsEventActions};
export const DocumentActions = {...DocumentsDocumentActions};
export const Actions = {...CommandActions, ...EventActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type EventActions = ActionsUnion<typeof EventActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>