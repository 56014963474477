import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import FileViewer from 'react18-file-viewer';
import { PreviewObject } from '../../../models/files';

export interface PreviewProps extends RouteComponentProps {
    previewObject?: PreviewObject
}
class Preview extends React.Component<PreviewProps> {
    constructor(props: PreviewProps) {
        super(props);   
    }


    render(){
        const { previewObject } = this.props;
        return (
            <>  
                {previewObject && 
                    <> 
                        <div style={{width: '100%', height: 'calc(100vh - 300px)'}}>
                            {previewObject.ext !== 'pdf' &&
                                <FileViewer fileType={previewObject.ext} filePath={previewObject.url} />
                            }
                            {previewObject.ext === 'pdf' &&
                                <object data={previewObject.url} width="100%" height="100%">
                                    <p>Alternative text - include a link <a href={previewObject.url}>to the PDF!</a></p>
                                </object>                      
                            }
                        </div>        
                    </>
                }     
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    previewObject: (state) => (getGenericModal('preview')(state)) ? (getGenericModal('preview')(state) as any).contextItem as PreviewObject : undefined,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItem: ModalDocumentAction.setContextItem,
        setContextItemProperty: ModalDocumentAction.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Preview));