import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {CommandActions} from '../../../redux/actions/documents';
import Dropzone from 'react-dropzone'
import _, { size } from 'lodash';
import { ProgressIndicator } from '@fluentui/react';
import { ModalCommandActions } from '../../../redux/actions/modal';
import { getDocumentsToUpload, getDocumentsToUploadTotal } from '../../../redux/reducers/documents';
import { getFileExtension } from '../../../utils/functions';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getClassNames } from './index.classNames';

export interface ReserveNumberSupplierProps extends RouteComponentProps, WithTranslation {
    loadingContent: boolean,
    documentsToUploadTotal: number,
    documentsToUpload: number,
    reserveNumberSupplier: typeof CommandActions.reserveNumberSupplier,
    openGenericModal: typeof ModalCommandActions.openModal
}

export interface ReserveNumberSupplierState {

}
class ReserveNumberSupplier extends React.Component<ReserveNumberSupplierProps, ReserveNumberSupplierState> {
    constructor(props: ReserveNumberSupplierProps) {
        super(props);   
        this.state = {

        }
    }
    
    onDrop = (accepted: any, rejected: any) => {
        const {t, reserveNumberSupplier, openGenericModal} = this.props;
        if(accepted.length === 1){
            const file = accepted[0];
            if(file.size > 0 && (getFileExtension(file.name) === 'xlsx'))
                reserveNumberSupplier({file});
            else if(file.size === 0)
                openGenericModal({id: 'warning', contextItem: t('The file you uploaded is empty')});
            else    
                openGenericModal({id: 'warning', contextItem:  t('Please select a .xlsx file')});
        }
        else if(accepted.length > 1)
            openGenericModal({id: 'warning', contextItem:  t('Only one file can be uploaded')});
        else
            openGenericModal({id: 'warning', contextItem:  t('Please select a .xlsx file')});
    }

    render(){
        const { loadingContent, documentsToUploadTotal, documentsToUpload, t } = this.props;
        const currentDoc = (documentsToUpload > 0) ? (documentsToUploadTotal - documentsToUpload + 1) : undefined
        const styles = getClassNames();
        return (
            <div>
                <ProgressIndicator description={(currentDoc) ? (currentDoc + " of " + documentsToUploadTotal) : undefined} styles={{root: {height: 35, marginBottom: 10}}} progressHidden={ !loadingContent } percentComplete={(currentDoc) ? ((currentDoc) / documentsToUploadTotal) : 0}/> 
                <Dropzone
                        accept={{
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ['.xlsx'],
                        }}
                        onDrop={this.onDrop}
                        disabled={loadingContent}
                        noDragEventsBubbling={true}
                    >
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className={styles.containerDropzone}>
                                <input {...getInputProps()} />
                            <div style={{padding: 5}}>Drop the .xlsx file here, or click to select file to upload.</div>
                            </div>
                        )}
                </Dropzone> 
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    documentsToUpload: getDocumentsToUpload,
    documentsToUploadTotal: getDocumentsToUploadTotal,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        reserveNumberSupplier: CommandActions.reserveNumberSupplier,
        openGenericModal: ModalCommandActions.openModal
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(ReserveNumberSupplier)));