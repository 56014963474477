 import React from 'react';
import { IColumn, IDetailsColumnProps } from '@fluentui/react/lib/DetailsList';
import {CommandActions as FiltersCommandActions} from '../../redux/actions/filters';
import _ from 'lodash';
import { FontIcon, FontSizes, getTheme, Icon, IconButton, IContextualMenuListProps, IContextualMenuProps, IDropdownOption, Link, Selection, Text, TooltipHost } from '@fluentui/react';
import { Document, FileToDelete, getEmptyParentFileForm, getParentFileForm } from '../../models/document';
import {CommandActions as DocumentCommandActions} from '../../redux/actions/document';
import { Filters } from '../../models/filters';
import { HistoryPathType, isExternalApp } from '../../utils/costants';
import { getClassNames } from './index.classNames';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { CommandActions as DocumentsCommandActions } from '../../redux/actions/documents';
import { CommandActions as ModalCommandActions } from '../../redux/actions/modal';
import { formatDate, fromLongToShortLanguage, getFileExtension, getPurposeLabel, showParentFileChangeIcon, showStatusFilter } from '../../utils/functions';
import { TFunction } from 'i18next';
import { Folder } from '../../models/folder';
import { languages } from '../../translations';

const deleteFile = (id: string, fileToDelete: FileToDelete, email?: string,  openGenericModal?: typeof ModalCommandActions.openModal) => {
  if(fileToDelete && fileToDelete.file && fileToDelete.file.createdBy && fileToDelete.file.createdBy !== email)
    window.location.href = "mailto:" + fileToDelete.file.createdBy + "?subject=Exod - File " + fileToDelete.file.fileName + " deleted by " + email;
  if(openGenericModal)
    openGenericModal({id: 'deleteFile', optional: {...fileToDelete}})
}

const renderFlag = (option?: IDropdownOption): JSX.Element => {
  return (
      <div key={(option) ? option.key : undefined} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 10  }}>
          {option && (option as any).icon}
          <span>{(option) ? option.text : undefined}</span>
      </div>     
  );
};

export const getColumns = (email: string,
                           t: TFunction,
                           canRead: boolean,
                           canDeleteFiles: boolean,
                           selection: Selection,
                           getFilterPanel: any, 
                           getDocumentAndOpenModal: typeof DocumentCommandActions.getDocumentAndOpenModal, 
                           getFilters: () => Filters, 
                           showOrHideCallout: typeof FiltersCommandActions.showOrHideCallout, 
                           downloadOrShowFile: typeof DocumentsCommandActions.downloadOrShowFile,
                           handleClick: any,
                           orderedColumn: {name: string, direction: string},
                           historyName: HistoryPathType,
                           openGenericModal?: typeof ModalCommandActions.openModal,
                           canUploadParent?: boolean,
                           folders?: Folder[],
                           isPartner?: boolean,
                           theme?: any) => {
      const styles = getClassNames(false);

      const filterPanelOpened = getFilterPanel() as boolean;
      let language_options: {key: string, text: string}[] = _.map(languages, l => {return { key: l.code, text: l.description }});

      const colums: IColumn[] = [
          ...(canUploadParent ? [{
            key: 'uploadParent',
            name: 'Upload Parent',
            fieldName: 'uploadParent',
            minWidth: 5,
            maxWidth: 5,
            isResizable: false,
            isIconOnly: true,
            //onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'number',
            isPadded: false,
            onRender: (item: Document) => {
              if(item.status && (item.status.name === 'ready-for-supplier' || item.status.name === 'supplier-rejected')){
                return (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                      {openGenericModal && <FontIcon iconName={'Upload'} title={t('Upload file')} onClick={() => {
                          openGenericModal({id: 'uploadParent', contextItem: {files: [], fileForm: getParentFileForm(item, false)}})
                        }
                      } style={{fontSize: FontSizes.xLarge, color: theme.palette.themePrimary, cursor: 'pointer'}} ></FontIcon>}
                  </div>
                );              
              }
            }
          }] : []),   
          ...(canRead ? [{
            key: 'filesNumber',
            name: t('Files'),
            fieldName: 'filesNumber',
            minWidth: 45,
            maxWidth: 45,
            isResizable: false,
            isIconOnly: true,
            //onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'number',
            isPadded: false,
            onRender: (item: Document) => {

              let files = (item.files && item.files.length > 0) ? _.orderBy(item.files, ['isParent', 'isComment', 'fileName'], ['desc', 'asc', 'asc']) : [];
              let parentFile = (item.files && item.files.length > 0) ? _.find(item.files, f => f.isParent) : undefined;
              let menuButtonProps: IContextualMenuProps = {items: [], directionalHintFixed: true}
       
              _.forEach(_.filter(files, f => f.isParent), a => {
                menuButtonProps = {
                  items: [
                    {
                      key: 'docNumbName',
                      text: 'Use SMS Doc.Number as filename',
                      onClick: () => {downloadOrShowFile({id: a.id, fileName: a.fileName})}
                    },
                    {
                      key: 'clientNumbName',
                      text: 'Use Customer Doc.Number as filename',
                      onClick: () => {downloadOrShowFile({id: a.id, fileName: a.fileName, clientNumber: (item.customerDocNumber + '_' + item.customerDocIndex)})},
                      disabled: !item.customerDocNumber
                      
                    },
                    {
                      key: 'projectNumbName',
                      text: 'Use Project Doc.Number as filename',
                      onClick: () => {downloadOrShowFile({id: a.id, fileName: a.fileName, clientNumber: (item.projectDocumentNumber)})},
                      disabled: !item.projectDocumentNumber            
                    },
                  ],
                  directionalHintFixed: true,
                };
              });

              const menuProps: IContextualMenuProps = {
                  items: _.map(files, a => {return {key: a.id as string, text: a.fileName, }}),
                  directionalHintFixed: true,
                  onRenderMenuList: (props?: IContextualMenuListProps, defaultRender?: any ) => { return (files && files.length > 0 && props && props.items.length > 0) ? <>{
                          _.map(files, (a, index) => {
                            
                              let fileToDelete: FileToDelete = {documentId: item.id, file: {...a}};
                              return  <div key={index}>
                                          <div className={styles.attachment} style={{display: 'flex', justifyContent: 'space-between', padding: 8, alignItems: 'center', gap: 15}}>  
                                              <div style={{display: 'flex', alignItems: 'center'}}>
                                                <Icon key={index + '_i'} {...getFileTypeIconProps({extension: (a.fileName) ? getFileExtension(a.fileName) : '', size: 16}) }/>
                                                <Text key={index + '_t'} variant="smallPlus" style={{marginLeft: 10, fontWeight: (a.isParent) ? 'bold' : undefined}}>{a.fileName}</Text> 
                                              </div>
                                              <div style={{display: 'flex', alignItems: 'center', gap: (!a.isParent) ? 15 : 5}}>
                                                {/* {canDeleteFiles && item.status && item.status.name !== 'canceled' && openGenericModal && !a.isParent && <FontIcon iconName={"Cancel"} title={t("Delete file")} style={{cursor: "pointer", color: 'red', marginTop: 2 }} onClick={() => {deleteFile('deleteFile', fileToDelete, email, openGenericModal)}}/>  } */}
                                                <Text key={index + '_p'} variant="smallPlus" style={{}}>{(a.isComment) ? '[C]' : ((!a.isComment && !a.isParent) ? '[A]' : '')}</Text>
                                                {/* {canDeleteFiles && item.status && item.status.name !== 'canceled' && showParentFileChangeIcon(historyName) && a.isParent && openGenericModal && <FontIcon iconName={"ChangeEntitlements"} title={t("Replace file")} style={{cursor: "pointer", color: '#1E20C2', marginTop: 2 }} onClick={() => {openGenericModal({id: 'uploadDocuments', contextItem: {files: [], fileForm: getEmptyParentFileForm(a.fileName)}, optional: {replaceId: a.id}})}}/>  } */}
                                                {a.isParent && 
                                                  <IconButton
                                                    menuProps={menuButtonProps}
                                                    iconProps={{iconName: 'Download'}}
                                                    title="Download"
                                                    ariaLabel="Download"
                                                    className={styles.menuButton}
                                                    styles={{menuIconHovered: styles.menuButton}}
                                                    style={{cursor: "pointer", marginTop: 2, height: 5, }}
                                                  />                                               
                                                }                                     
                                                {!a.isParent && <FontIcon iconName={"Download"} title={t("Download file")} style={{cursor: "pointer", color: 'green', marginTop: 2 }}  onClick={() => {downloadOrShowFile({id: a.id, fileName: a.fileName})}}/>}
                                              </div>
                                          </div>                                   
                                      </div>
                          })            
                      }</> : <></>
                  },
                  styles: {root: {maxHeight: 256}}
              };
              return (
                files && files.length > 0 &&
                    <div style={{display: 'flex', alignItems: 'center', height: 21}}>
                      <IconButton menuProps={menuProps} title={'Files'} iconProps={{...getFileTypeIconProps({extension: (parentFile && parentFile.fileName) ? ('.' + parentFile.fileName.split('.')[1]) : '', size: 16}) }} styles={{ menuIcon: {display: 'none'} }}></IconButton>
                      <Text>{files.length}</Text>  
                    </div>                               
              );
            }
          }] : []),     
          {
            key: 'icon',
            name: 'icon',
            fieldName: 'icon',
            minWidth: 25,
            maxWidth: 25,
            isResizable: false,
            isIconOnly: true,
            //onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'number',
            isPadded: false,
            onRender: (item: Document) => {

              let isCommented = (item.comments.length > 0 || _.some(item.files, f => f.isComment));
              let isWithinFolder = (item.folderIds.length > 0) ? true : false;
              const documentFolders = (folders && folders.length > 0 && isWithinFolder) ? _.filter(folders, f => item.folderIds.includes(f.id)) : [];
              
              return ( (isCommented || isWithinFolder) &&
                <div style={{display: 'flex', alignItems: 'center', height: 21, gap: 5}}>
                    {isCommented && <FontIcon iconName={"CommentActive"} title={"Commented"} style={{color: 'green', marginTop: 2 }}/>}
                    {historyName === 'dashboard' && isWithinFolder && <FontIcon iconName={"FabricFolder"} title={(documentFolders.length > 0) ? _.join(_.map(documentFolders, df => df.description), ', ') : ''} style={{color: '#D6A107', marginTop: 2 }}/>}
                </div>                               
              );
            }
          },
          {
            key: 'status',
            name: t('Status'),
            fieldName: 'Status',
            minWidth: 60,
            maxWidth: 60,
            isResizable: false,
            data: 'string',
            //onColumnClick: (handleClick) ? handleClick : () => {},
            isPadded: false,
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  { showStatusFilter(historyName) && 
                    <FontIcon iconName={(filters.status.length > 0) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.status) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'status', ref: _elementRef}); e.stopPropagation();}}/>                 
                  }
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Status').toString()}</Text>
                </div>
              );
            },       
            onRender: (item: Document) => {
              if(item.status){
                return (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <FontIcon iconName={item.status.icon} title={item.status.description} style={{color: item.status.color, fontSize: FontSizes.xLarge}} ></FontIcon>
                  </div>
                );              
              }
            }
          },
          {
            key: 'date',
            name: t('Date'),
            fieldName: 'date',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: false,   
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  <FontIcon iconName={(filters && (filters.from || filters.to)) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && (filters.from || filters.to)) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'dates_callout', ref: _elementRef}); e.stopPropagation();}}/> 
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Date').toString()}</Text>
                </div>
              );
            },
            onRender: (item: Document) => {
              return (item.status) ? formatDate(item.status.date) : ''
            },
            isSorted: (orderedColumn && orderedColumn.name === 'date') ? true : false,
            isSortedDescending: (orderedColumn && orderedColumn.name === 'date' && orderedColumn.direction === 'desc') ? true : false,
          },
        {
          key: 'documentNumber',
          name: t('SMS Doc.Number'),
          fieldName: 'documentNumber',
          minWidth: 170,
          maxWidth: 170,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.documentNumber.length > 0) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.documentNumber.length > 0) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'documentNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('SMS Doc.Number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document, index?: number) => {       
            if(item.documentNumber){
              return ( 
                  <TooltipHost content={item.documentNumber}> 
                    {canRead && 
                      <Link onClick={() => {
                        if(selection.isAllSelected()){
                          let rows = selection.getSelection() as any[];
                          selection.setAllSelected(false);
                          selection.setIndexSelected(rows.indexOf(item), true, false);                      
                        }
                        getDocumentAndOpenModal({documentId: item.id as number})
                      }}>{item.documentNumber}</Link>       
                    }
                    {!canRead && 
                      <div>{item.documentNumber}</div>
                    }
                  </TooltipHost>);              
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'documentNumber') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'documentNumber' && orderedColumn.direction === 'desc') ? true : false,
        },
        ...(historyName !== 'reservedNumbers' && historyName !== 'supplierMaterial' && historyName !== 'publishMaterial' ? [{
          key: 'projectDocumentNumber',
          name: t('Project Doc.Number'),
          fieldName: 'projectDocumentNumber',
          minWidth: 320,
          maxWidth: 320,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.projectDocumentNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.projectDocumentNumber) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'projectDocumentNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Project Doc.Number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.projectDocumentNumber){
              return ( <TooltipHost content={item.projectDocumentNumber}> <div>{item.projectDocumentNumber}</div> </TooltipHost>);                   
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'projectDocumentNumber') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'projectDocumentNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),       
        {
          key: 'description',
          name: t('Description'),
          fieldName: 'description',
          minWidth: 220,
          maxWidth: 220,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.description) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.description) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'description', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Description')}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.description){
              return ( <TooltipHost content={item.description}> <div>{item.description}</div> </TooltipHost>);                   
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'description') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'description' && orderedColumn.direction === 'desc') ? true : false,
        }, 
        {
          key: 'description2',
          name: t('Description 2'),
          fieldName: 'description2',
          minWidth: 220,
          maxWidth: 220,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.description2) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.description2) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'description2', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Description 2')}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.description2){
              return ( <TooltipHost content={item.description2}> <div>{item.description2}</div> </TooltipHost>);                   
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'description2') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'description2' && orderedColumn.direction === 'desc') ? true : false,
        },     
        {
          key: 'sapPart',
          name: t('SAP Doc.Part'),
          fieldName: 'sapPart',
          minWidth: 110,
          maxWidth: 110,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.sapPart) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.sapPart) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'sapPart', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('SAP Doc.Part').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.sapPart){
              return ( <TooltipHost content={item.sapPart}> <div>{item.sapPart}</div> </TooltipHost>);                   
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'sapPart') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'sapPart' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'documentIndex',
          name: t('Index'),
          fieldName: 'documentIndex',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.documentIndex) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.documentIndex) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'documentIndex', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Index').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.documentIndex){
              return ( <div>{item.documentIndex}</div>);                             
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'revision') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'revision' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'sheet',
          name: t('Sheet number'),
          fieldName: 'sheet',
          minWidth: 110,
          maxWidth: 110,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.sheet) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.sheet) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'sheet', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Sheet number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.sheet){
              return ( <TooltipHost content={_.padStart(item.sheet.toString().toString(), 3, '0')}> <div>{_.padStart(item.sheet.toString().toString(), 3, '0')}</div> </TooltipHost>);                    
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'sheet') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'sheet' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'sapType',
          name: t('SAP Doc.Type'),
          fieldName: 'sapType',
          minWidth: 110,
          maxWidth: 110,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.sapType) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.sapType) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'sapType', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('SAP Doc.Type').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.sapType){
              return ( <TooltipHost content={item.sapType}> <div>{item.sapType}</div> </TooltipHost>);                   
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'sapType') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'sapType' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'language',
          name: t('Language'),
          fieldName: 'language',
          minWidth: 110,
          maxWidth: 110,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.language) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.language) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'language', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Language').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
                return (          
                   <TooltipHost content={item.language}> 
                      {item.language}
                    </TooltipHost>          
                );                           
          },
          isSorted: (orderedColumn && orderedColumn.name === 'language') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'language' && orderedColumn.direction === 'desc') ? true : false,
        },
        ...(historyName !== 'reservedNumbers' && historyName !== 'supplierMaterial' && historyName !== 'publishMaterial' ? [{
          key: 'transmittalId',
          name: t('Transmittal Id'),
          fieldName: 'transmittalId',
          minWidth: filterPanelOpened ? 160 : 120,
          maxWidth: filterPanelOpened ? 160 : 120,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.transmittalId) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.transmittalId) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'transmittalId', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Transmittal Id').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.transmittalId){
              return ( <TooltipHost content={item.transmittalId}> <div>{item.transmittalId}</div> </TooltipHost>);                   
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'transmittalId') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'transmittalId' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),  
        ...(historyName !== 'reservedNumbers' && historyName !== 'supplierMaterial' && historyName !== 'publishMaterial' ? [{
          key: 'transmittalNumber',
          name: t('Transmittal N°'),
          fieldName: 'transmittalNumber',
          minWidth: filterPanelOpened ? 160 : 120,
          maxWidth: filterPanelOpened ? 160 : 120,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.transmittalNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.transmittalNumber) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'transmittalNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Transmittal N°')}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.transmittalNumber){
              return ( <TooltipHost content={item.transmittalNumber}> <div>{item.transmittalNumber}</div> </TooltipHost>);                   
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'transmittalNumber') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'transmittalNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),   
        ...(historyName !== 'reservedNumbers' && historyName !== 'publishMaterial' && historyName !== 'supplierMaterial' ? [{
            key: 'purpose',
            name: t(getPurposeLabel(historyName)),
            fieldName: 'purpose',
            minWidth: filterPanelOpened ? 110 : 140,
            maxWidth: filterPanelOpened ? 110 : 140,
            isResizable: true,
           // onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: false,   
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  <FontIcon iconName={(filters && filters.purpose) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.purpose) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'purpose', ref: _elementRef}); e.stopPropagation();}}/> 
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t(getPurposeLabel(historyName)).toString()}</Text>
                </div>
              );
            },
            onRender: (item: Document) => {
              if(item.purpose){
                return ( <TooltipHost content={item.purpose.name}> <div>{item.purpose.name}</div> </TooltipHost>);                                
              }
            },
            // isSorted: (orderedColumn && orderedColumn.name === 'tdNumber') ? true : false,
            // isSortedDescending: (orderedColumn && orderedColumn.name === 'tdNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []), 
        ...(historyName === 'dashboard' || historyName === 'pending' ? [{
          key: 'customerDocIndex',
          name: t('Customer Doc.Index'),
          fieldName: 'customerDocIndex',
          minWidth: 160,
          maxWidth: 160,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.customerDocIndex) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.customerDocIndex) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'customerDocIndex', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Customer Doc.Index').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.customerDocIndex){
              return ( <TooltipHost content={(item.customerDocIndex) ? item.customerDocIndex.toString() : undefined}> <div>{item.customerDocIndex}</div> </TooltipHost>);                    
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'customerDocIndex') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'customerDocIndex' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),     
        ...(historyName === 'dashboard' || historyName === 'pending' ? [{
          key: 'customerDocNumber',
          name: t('Customer Doc.Number'),
          fieldName: 'customerDocNumber',
          minWidth: 180,
          maxWidth: 180,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.customerDocNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.customerDocNumber) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'customerDocNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Customer Doc.Number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.customerDocNumber){
              return ( <TooltipHost content={item.customerDocNumber}> <div>{item.customerDocNumber}</div> </TooltipHost>);              
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'customerDocNumber') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'customerDocNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),     
        ...(historyName !== 'dashboard' && historyName !== 'pending' ? [{
          key: 'supplierDocIndex',
          name: t('Supplier Doc.Index'),
          fieldName: 'supplierDocIndex',
          minWidth: 140,
          maxWidth: 140,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.supplierDocIndex) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.supplierDocIndex) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'supplierDocIndex', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Supplier Doc.Index').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.supplierDocIndex){
              return ( <TooltipHost content={(item.supplierDocIndex) ? item.supplierDocIndex.toString() : undefined}> <div>{item.supplierDocIndex}</div> </TooltipHost>);                    
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'supplierDocIndex') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'supplierDocIndex' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),     
        ...(historyName !== 'dashboard' && historyName !== 'pending' ? [{
          key: 'supplierDocNumber',
          name: t('Supplier Doc.Number'),
          fieldName: 'supplierDocNumber',
          minWidth: 160,
          maxWidth: 160,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.supplierDocNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.supplierDocNumber) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'supplierDocNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Supplier Doc.Number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.supplierDocNumber){
              return ( <TooltipHost content={item.supplierDocNumber}> <div>{item.supplierDocNumber}</div> </TooltipHost>);              
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'supplierDocNumber') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'supplierDocNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),     
        ...(historyName === 'reservedNumbers' || historyName === 'supplierMaterial' || historyName === 'publishMaterial' ? [{
          key: 'fileType',
          name: t('File type'),
          fieldName: 'fileType',
          minWidth: filterPanelOpened ? 90 : 110,
          maxWidth: filterPanelOpened ? 90 : 110,
          isResizable: true,
         // onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.fileType) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.fileType) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'fileType', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('File type').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.fileType){
              return ( <TooltipHost content={item.fileType}> <div>{item.fileType}</div> </TooltipHost>);                                
            }
          },
          // isSorted: (orderedColumn && orderedColumn.name === 'tdNumber') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'tdNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),  
        ...(isExternalApp || (!isExternalApp && (historyName === 'reservedNumbers' || historyName === 'supplierMaterial')) ? [{
          key: 'plant',
          name: t('Plant'),
          fieldName: 'plant',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
         // onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRender: (item: Document) => {
            if(item.fileType){
              return ( <TooltipHost content={(item.plant) ? item.plant.code : ''}> <div>{(item.plant) ? item.plant.code : ''}</div> </TooltipHost>);                                
            }
          },
          // isSorted: (orderedColumn && orderedColumn.name === 'tdNumber') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'tdNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),     
        ...((isExternalApp || !isExternalApp && (historyName === 'reservedNumbers' || historyName === 'supplierMaterial')) ? [{
          key: 'plantUnit',
          name: t('Plant Unit'),
          fieldName: 'plantUnit',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
         // onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRender: (item: Document) => {
            if(item.fileType){
              return ( <TooltipHost content={(item.plantUnit) ? item.plantUnit.code : ''}> <div>{(item.plantUnit) ? item.plantUnit.code : ''}</div> </TooltipHost>);                                
            }
          },
          // isSorted: (orderedColumn && orderedColumn.name === 'tdNumber') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'tdNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),  
        ...((isExternalApp || !isExternalApp && (historyName === 'reservedNumbers' || historyName === 'supplierMaterial')) ? [{
          key: 'plantSection',
          name: t('Plant Section'),
          fieldName: 'plantSection',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
         // onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRender: (item: Document) => {
            if(item.fileType){
              return ( <TooltipHost content={(item.plantSection) ? item.plantSection.code : ''}> <div>{(item.plantSection) ? item.plantSection.code : ''}</div> </TooltipHost>);                                
            }
          },
          // isSorted: (orderedColumn && orderedColumn.name === 'tdNumber') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'tdNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),  
        ...((isExternalApp || !isExternalApp && (historyName === 'reservedNumbers' || historyName === 'supplierMaterial')) ? [{
          key: 'equipment',
          name: t('Equipment'),
          fieldName: 'equipment',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
         // onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRender: (item: Document) => {
            if(item.fileType){
              return ( <TooltipHost content={(item.equipment) ? item.equipment.code : ''}> <div>{(item.equipment) ? item.equipment.code : ''}</div> </TooltipHost>);                                
            }
          },
          // isSorted: (orderedColumn && orderedColumn.name === 'tdNumber') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'tdNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),                   
    ];
    return colums;
}