import {Actions, DocumentActions, CommandActions, EventActions, FETCH_EVENT_TYPES, FETCH_USER_RULES, SAVE_AND_SET_MAIL, SAVE_AND_SET_TEAMS, SAVE_RULE, SAVE_MULTIPLE_RULES, DELETE_MULTIPLE_RULES, FETCH_USERS_RULES} from "../../actions/notifySettings";
import {ApiConfigActions, AuthConfigReducer} from "@pwbapps/reduxcore";
import { createMultipleRulesUrl, createRuleUrl, deleteMultipleRulesUrl, deleteRuleUrl, editRuleUrl, getEventTypesUrl, getUserRulesUrl, getUsersRulesUrl } from "../../../utils/urls";
import { EventType } from "../../../models/eventType";
import { NotifyRule } from "../../../models/notifyRule";
import _ from "lodash";
import { getEventTypes, getUiRules } from "../../reducers/notifySettings";
import { isExternalApp } from "../../../utils/costants";
import { getSelectedProjectId } from "../../reducers/project";
import { getStakeHolderId } from "../../reducers/initialization";
import { getReports } from "../../reducers/sharedData";
import { AppUser } from "../../../models/administration";
import { CommandActions as AdministrationCommandActions, DocumentActions as AdministrationDocumentActions } from "../../actions/administrationModal";
import { CommandActions as LoaderCommandActions } from "../../actions/loader";
import { getEmail } from "@pwbapps/reduxcore/dist/redux/reducers/auth";
import { getLoader } from "../../selectors/loader";
import { use } from "i18next";
import { getExternalEditUsers, getInternalEditUsers } from "../../reducers/administrationModal";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { MessageBarType } from "@fluentui/react";

export const notifySettingsMiddleware = ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions | ApiConfigActions.EventActions) => {
    next(action);
    let rule: NotifyRule | undefined = undefined;
    switch (action.type) {

        case FETCH_EVENT_TYPES:      
            next(DocumentActions.setEventTypes({eventTypes: []}));  
            next(DocumentActions.setUiRules({uiRules: []}));   
            dispatch(ApiConfigActions.CommandActions.apiRequest({request: {method: 'GET', url: getEventTypesUrl, feature: FETCH_EVENT_TYPES}}));        
            break;

        case FETCH_USER_RULES:
            var email = AuthConfigReducer.getEmail(getState());
            if(email)         
                dispatch(ApiConfigActions.CommandActions.apiRequest({request: {method: 'GET', url: getUserRulesUrl(getSelectedProjectId(getState()) as number, email), feature: FETCH_USER_RULES}}));        
            break;

        case FETCH_USERS_RULES: {
            let users = action.payload.users;
            let isExternal = action.payload.isExternal;
            if(users && users.length > 0)
                dispatch(ApiConfigActions.CommandActions.apiRequest({request: {method: 'GET', url: getUsersRulesUrl(getSelectedProjectId(getState()) as number, _.map(users, u => u.email as string)), feature: FETCH_USERS_RULES, returnObject: {users, isExternal}}}));          
            break;
        }

        case SAVE_AND_SET_MAIL:
            next(DocumentActions.setRuleMail({index: action.payload.index, value: action.payload.value}));
            var uiRules = getUiRules(getState());
            rule = _.find(uiRules, ur => ur.index === action.payload.index);
            if(rule){
                rule.mail = action.payload.value;
                dispatch(EventActions.saveRule({rule: rule}));        
            }  
            break;

        case SAVE_AND_SET_TEAMS:
            next(DocumentActions.setRuleTeams({index: action.payload.index, value: action.payload.value}));
            var uiRules = getUiRules(getState());
            rule = _.find(uiRules, ur => ur.index === action.payload.index);
            if(rule){
                rule.msChat = action.payload.value;
                dispatch(EventActions.saveRule({rule: rule}));        
            }  
            break;

        case SAVE_RULE:
            var rulePost = {...action.payload.rule};
            delete rulePost.index;
            if(!rulePost.eventTypeRuleId){
                // create
                rulePost.ruleJson = "{'project':'" + getSelectedProjectId(getState()) + "','stakeHolderId':'" + ((!isExternalApp) ? ("") : getStakeHolderId(getState())) + "'}";
                dispatch(ApiConfigActions.CommandActions.apiRequest({request: {method: 'POST', body: rulePost, url: createRuleUrl(), feature: SAVE_RULE, returnObject: {index: action.payload.rule.index, isNew : true}}}));   
            }
            else if(rulePost.eventTypeRuleId && (rulePost.mail || rulePost.msChat)){
                // edit
                rulePost.ruleJson = "{'project':'" + getSelectedProjectId(getState()) + "','stakeHolderId':'" + ((!isExternalApp) ? ("") : getStakeHolderId(getState())) + "'}";
                dispatch(ApiConfigActions.CommandActions.apiRequest({request: {method: 'PUT', body: rulePost, url: editRuleUrl(rulePost.eventTypeRuleId), feature: SAVE_RULE, returnObject: {index: action.payload.rule.index, editedRule: action.payload.rule, isEdit : true}}}));   
            }
            else if(rulePost.eventTypeRuleId && (!rulePost.mail && !rulePost.msChat)){
                // delete
                dispatch(ApiConfigActions.CommandActions.apiRequest({request: {method: 'POST', url: deleteRuleUrl(rulePost.eventTypeRuleId, AuthConfigReducer.getEmail(getState()) as string), feature: SAVE_RULE, returnObject: {index: action.payload.rule.index, isDelete : true}}}));  
            }
            break;

        case SAVE_MULTIPLE_RULES:
            const users = action.payload.users;
            if(users.length > 0){
                if(!action.payload.isExternal)
                    next(LoaderCommandActions.setLoadingInternalNotifications({feature: 'administration', loading: true}));
                else
                    next(LoaderCommandActions.setLoadingExternalNotifications({feature: 'administration', loading: true}));
                let rules: NotifyRule[] = [];
                _.forEach(users, u => {  
                    if(u.notifications && u.notifications.length > 0){
                        const rule: NotifyRule = {
                            userId: u.email,
                            userName: u.email,
                            mail: true,
                            msChat: true,  
                            msNotificationTeam: false,
                            eventTypeId: [..._.map(_.filter(u.notifications, no => !no.eventTypeRuleId), n => n.eventTypeId)] as number[], // aggiungo solo le notifiche nuove
                            ruleJson: `{'project':'${getSelectedProjectId(getState())}','stakeHolderId':'${(u.stakeHolderId) ? u.stakeHolderId : ''}'}`,                              
                        }
                        rules = [...rules, rule];         
                    }
                });
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'POST', body: [...rules], url: createMultipleRulesUrl(), feature: SAVE_MULTIPLE_RULES, returnObject: {isExternal: action.payload.isExternal}}}));  
            } 
            break;

            case DELETE_MULTIPLE_RULES: {
                const users = action.payload.users;
                const projectId = getSelectedProjectId(getState());
                if(users.length > 0 && projectId){
                    if(!action.payload.isExternal)
                        next(LoaderCommandActions.setLoadingInternalNotificationsRemove({feature: 'administration', loading: true}));
                    else
                        next(LoaderCommandActions.setLoadingExternalNotificationsRemove({feature: 'administration', loading: true}));
                    let deleteUsersRules: any[] = [];
                    _.forEach(users, u => {
                        let notifications = (!action.payload.all && u.notificationsToBeRemoved && u.notificationsToBeRemoved.length > 0) ? [...u.notificationsToBeRemoved] : ((action.payload.all && u.notifications && u.notifications.length > 0) ? [...u.notifications] : []);
                        deleteUsersRules = (notifications.length > 0) ? 
                            [...deleteUsersRules,  { 
                                                        userId: u.email as string, 
                                                        ruleJson: `{'project':'${projectId}','stakeHolderId':'${(u.stakeHolderId) ? u.stakeHolderId : ''}'}`,
                                                        eventTypeId: _.map(notifications, n => n.eventTypeId as number)
                                                    }
                            ]
                            : deleteUsersRules;                  
                    });
                    if(deleteUsersRules.length > 0){
                        dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                            request: { 
                                method: 'DELETE', 
                                body: [...deleteUsersRules], 
                                url: deleteMultipleRulesUrl(), 
                                feature: DELETE_MULTIPLE_RULES
                            }
                        }));  
                    }
                } 
                break;
            }

        case ApiConfigActions.API_SUCCESS:   
            apiSuccessMiddleware(dispatch, next, getState, action);        
            break;

        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(dispatch, next, action);
            break;

        default:
            break;
    }
};

const apiSuccessMiddleware = (dispatch: any, next: any, getState: any, action: ApiConfigActions.ApiSuccessAction ) => {
    let eventTypes: EventType[] = [];
    switch(action.meta.feature){
        case FETCH_EVENT_TYPES:          
            const reports = getReports(getState());
            var result = (action.payload && action.payload.length > 0) ? action.payload[0] : undefined;   
            eventTypes = (result) ? result.EventTypes : [];   
            let eventTypesMapped: EventType[] = _.map(eventTypes, e => new EventType(e));
            eventTypesMapped = _.filter(eventTypesMapped, e => _.map(reports, r => r.name).includes(e.name) || e.name === 'TdCreated');
            next(DocumentActions.setEventTypes({eventTypes: eventTypesMapped}));
            next(DocumentActions.setEventApplicationId({id: result.EventApplicationId}));  
            dispatch(CommandActions.fetchUserRules());  
        break;

        case FETCH_USER_RULES:          
            var result = (action.payload && action.payload.length > 0) ? action.payload : undefined;   
            var rulesMapped = _.map(result, r => new NotifyRule(r));
            eventTypes = getEventTypes(getState());
            var uiRules: NotifyRule[] = [];
            _.forEach(eventTypes, (e, index) => {
                var matchingRule = _.find(rulesMapped, r => r.eventTypeId === e.eventTypeId);
                if(matchingRule)
                    uiRules = [...uiRules, {...matchingRule, index: index}];
                else
                    uiRules = [...uiRules, {...new NotifyRule(undefined, e, AuthConfigReducer.getEmail(getState()), AuthConfigReducer.getUsername(getState())), index: index}];
            });
            next(DocumentActions.setUiRules({uiRules: uiRules}));
        break;

        case FETCH_USERS_RULES:          
            var result = (action.payload && action.payload.length > 0) ? action.payload : undefined;   
            var rulesMapped = _.map(result, r => new NotifyRule(r));
            let users = action.meta.returnObject.users as AppUser[];
            let isExternal = action.meta.returnObject.isExternal as boolean;

            let usersWithNotifications: AppUser[] = [];
            _.forEach(users, u => {
                let userNotifications = _.filter(rulesMapped, r => r.userId && u.email && r.userId.toLocaleLowerCase() === u.email.toLocaleLowerCase()) as NotifyRule[];
                usersWithNotifications = [...usersWithNotifications, {...u, notifications: (userNotifications && userNotifications.length > 0) ? userNotifications: []}];
            });

            if(!isExternal){
                next(AdministrationCommandActions.setInternalUsers({value: _.orderBy(_.filter(usersWithNotifications, u => (u.email as string).toLocaleLowerCase() !== getEmail(getState())), iu => iu.name)}));
                next(LoaderCommandActions.setLoadingInternal({feature: 'administration', loading: false}));
            }
            else{
                next(AdministrationCommandActions.setExternalUsers({value: _.orderBy(usersWithNotifications, iu => iu.name)}));
                next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: false}));
            }
            const loading = getLoader('administration')(getState()).loading;
            if(loading)
                next(LoaderCommandActions.setLoading({feature: 'administration', loading: false}));
        break;

        case SAVE_RULE: 
            var result = action.payload;
            var index = action.meta.returnObject.index;
            var isNew = (action.meta.returnObject) ? action.meta.returnObject.isNew : false; 
            var isEdit = (action.meta.returnObject) ? action.meta.returnObject.isEdit : false; 
            var isDelete = (action.meta.returnObject) ? action.meta.returnObject.isDelete : false;         
            if(isNew && result.eventTypeRuleId)
                next(DocumentActions.setRuleId({index: index, value: result.eventTypeRuleId}));
            else if(isDelete)
                next(DocumentActions.setRuleId({index: index, value: undefined}));
            else if(isEdit){
                var editedRule = action.meta.returnObject.editedRule as NotifyRule;
                next(DocumentActions.setRule({rule: editedRule}));
            }
        break;

        case SAVE_MULTIPLE_RULES: {
            let isExternal = action.meta.returnObject.isExternal as boolean;
            if(!isExternal){
                next(LoaderCommandActions.setLoadingInternalNotifications({feature: 'administration', loading: false}));
                if(!getLoader('administration')(getState()).loadingInternalRemove && !getLoader('administration')(getState()).loadingInternal && !getLoader('administration')(getState()).loadingInternalNotificationRemove){
                    next(AdministrationDocumentActions.setUsersArray({arrayName: 'internalEditedUsers', users: []}));
                    dispatch(AdministrationCommandActions.fetchInternalUsers());
                    const externalEditUsers = getExternalEditUsers(getState());
                    if(externalEditUsers.length === 0){
                        next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User permissions saved succesfully'}));  
                    }
                }
            }
            else{
                next(LoaderCommandActions.setLoadingExternalNotifications({feature: 'administration', loading: false}));
                if(!getLoader('administration')(getState()).loadingExternalRemove && !getLoader('administration')(getState()).loadingExternal && !getLoader('administration')(getState()).loadingExternalNotificationRemove){  
                    next(AdministrationDocumentActions.setUsersArray({arrayName: 'externalEditedUsers', users: []}));
                    dispatch(AdministrationCommandActions.fetchExternalUsers());
                    const internalEditUsers = getInternalEditUsers(getState());
                    if(internalEditUsers.length === 0){
                        next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User permissions saved succesfully'}));  
                    }
                }
            }
        }
        break;

        case DELETE_MULTIPLE_RULES: {
            let isExternal = action.meta.returnObject.isExternal as boolean;
            if(!isExternal){
                next(LoaderCommandActions.setLoadingInternalNotificationsRemove({feature: 'administration', loading: false}));
                if(!getLoader('administration')(getState()).loadingInternalRemove && !getLoader('administration')(getState()).loadingInternal && !getLoader('administration')(getState()).loadingInternalNotification){
                    next(AdministrationDocumentActions.setUsersArray({arrayName: 'internalEditedUsers', users: []}));
                    dispatch(AdministrationCommandActions.fetchInternalUsers());
                    const externalEditUsers = getExternalEditUsers(getState());
                    if(externalEditUsers.length === 0){
                        next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User permissions saved succesfully'}));  
                    }
                }

            }
            else{
                next(LoaderCommandActions.setLoadingExternalNotificationsRemove({feature: 'administration', loading: false}));
                if(!getLoader('administration')(getState()).loadingExternalRemove && !getLoader('administration')(getState()).loadingExternal && !getLoader('administration')(getState()).loadingExternalNotification){  
                    next(AdministrationDocumentActions.setUsersArray({arrayName: 'externalEditedUsers', users: []}));
                    dispatch(AdministrationCommandActions.fetchExternalUsers());
                    const internalEditUsers = getInternalEditUsers(getState());
                    if(internalEditUsers.length === 0){
                        next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User permissions saved succesfully'}));  
                    }
                }
            }
            break;
        }
        

        default:
            break;
    }
}

const apiErrorMiddleware = (dispatch: any, next: any, action: ApiConfigActions.ApiErrorAction ) => {
    switch(action.meta.feature){
            
        default:
            break;
    }
}
