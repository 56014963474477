import { IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";
import _ from "lodash";
import { HistoryPathType, isExternalApp } from "./costants";
import { Status } from "../models/status";
import { CommandActions as ModalCommandActions } from "../redux/actions/modal";
import { Project } from "../models/project";
import { Event } from "../models/event";
import { getChangeStatusEmptyFileForm, getEmptyCommentFileForm } from "../models/document";
import { StakeHolder } from "../models/stakeHolder";
import moment from "moment";

export const getAvailableStatus = (currentStatus: Status, statusValues: Status[], project: Project ): Status[] => {
    let avaiableStatus: Status[] = [];

        if(currentStatus.name === 'published') {          
                avaiableStatus = [...avaiableStatus, _.find(statusValues, s => s.name === 'approved') as Status];                  
                avaiableStatus = [...avaiableStatus, _.find(statusValues, s => s.name === 'approved-comments') as Status];
                avaiableStatus = [...avaiableStatus, _.find(statusValues, s => s.name === 'rejected') as Status];        
        }
        else if(currentStatus.name === 'approved-comments' || currentStatus.name === 'approved' || currentStatus.name === 'rejected') {
            avaiableStatus = [_.find(statusValues, s => s.name === 'published') as Status];
        }

        if(currentStatus.name === 'reserved')
            avaiableStatus = [_.find(statusValues, s => s.name === 'supplier-uploaded') as Status];
        else if(currentStatus.name === 'supplier-uploaded'){
            avaiableStatus = [_.find(statusValues, s => s.name === 'reserved') as Status];
            avaiableStatus = [...avaiableStatus, _.find(statusValues, s => s.name === 'supplier-published') as Status];
        }
        else if(currentStatus.name === 'supplier-published'){
            avaiableStatus = [_.find(statusValues, s => s.name === 'supplier-approved') as Status];
            avaiableStatus = [...avaiableStatus, _.find(statusValues, s => s.name === 'supplier-rejected') as Status];
        }
        else if(currentStatus.name === 'supplier-rejected')
            avaiableStatus = [_.find(statusValues, s => s.name === 'supplier-uploaded') as Status];
        else if(currentStatus.name === 'waiting')
            avaiableStatus = [_.find(statusValues, s => s.name === 'saved') as Status]

    return _.filter(avaiableStatus, a => a) as Status[];
}

export const getStatusByCategory = (statusValues: Status[], historyName: HistoryPathType): Status[] => {
    if(historyName !== 'reservedNumbers')
        statusValues = _.filter(statusValues, s => s.category === 'Customer');
    else
        statusValues = _.filter(statusValues, s => s.category === 'Supplier');
    return statusValues;
}

export const getStatusBySection = (statusValues: Status[], historyName: HistoryPathType): Status[] => {
    if(historyName === 'reservedNumbers' && isExternalApp)
        statusValues = _.filter(statusValues, s => s.name === 'supplier-published' || s.name === 'supplier-uploaded' || s.name === 'supplier-approved');
    else if(historyName === 'reservedNumbers' && !isExternalApp)
        statusValues = getStatusByCategory(statusValues, historyName);
    else if(historyName === 'dashboard')
        statusValues = getStatusByCategory(statusValues, historyName);
    return statusValues;
}

export const getStatusSubMenuProps = (currentStatus: Status, statusValues: Status[], project: Project, historyName: HistoryPathType, openGenericModal: typeof ModalCommandActions.openModal): IContextualMenuProps => {
    let options: IContextualMenuProps = {items: getStatusSubMenuItems(currentStatus, statusValues, project, historyName, openGenericModal)};
    return options;
}

export const getStatusSubMenuItems = (currentStatus: Status, statusValues: Status[], project: Project, historyName: HistoryPathType, openGenericModal: typeof ModalCommandActions.openModal, singleCustomer?: StakeHolder): IContextualMenuItem[] => {
    let options: IContextualMenuItem[] = [];
    statusValues = getAvailableStatus(currentStatus, getStatusBySection(statusValues, historyName), project);
    _.forEach(statusValues, (v) => {
        let item: IContextualMenuItem = {
            key: (v.id as number).toString(), 
            name: v.description as string, 
            onClick: () => {
                openGenericModal({
                    id: 'changeStatus', 
                    contextItem: {
                        newStatus: new Status({statusId: v.id, statusName: v.description, category: v.category}),
                        fileForm: (v.name === 'approved-comments' || v.name === 'declined-comments' || v.name === 'supplier-rejected') ? getEmptyCommentFileForm() : getChangeStatusEmptyFileForm(singleCustomer),
                        files: (v.name === 'approved-comments' || v.name === 'declined-comments' || v.name === 'supplier-rejected') ? [] : undefined,
                    }
                })
            }, 
            iconProps: {iconName: v.icon as string, style: {color: v.color}}
        }
        options = [...options, item ];
    });
    return options;
}

export const getStatusDropdownOptions = (statusValues: Status[], historyName: HistoryPathType): {key: number, text: string, icon: string, color: string}[] => {
    let options: {key: number, text: string, icon: string, color: string}[] = [];
    statusValues = getStatusBySection(statusValues, historyName);
    _.forEach(statusValues, (v) => {
        options = [...options, { key: v.id as number, text: v.description as string, icon: v.icon as string, color: v.color as string }]
    });
    return options;
}

export const addSupplierHiddenStatus = (statusValues: Status[], historyName: HistoryPathType, allStatusValues: Status[]): Status[] => {
    return (isExternalApp && historyName === 'reservedNumbers' && _.find(statusValues, s => s.name === 'supplier-approved')) ? [...statusValues, ..._.filter(allStatusValues, s => s.name === 'waiting' || s.name === 'saved')] : statusValues;
}

export const setModelStatus = (id: number, date: string, historyName: HistoryPathType, allStatusValues: Status[]): Status => {
    let realStatus = {..._.find(allStatusValues, s => s.id === id) as Status, date: moment((date && date.includes('.')) ? date.split('.')[0] + 'Z' : date).toDate()};
    return (isExternalApp && historyName === 'reservedNumbers' && realStatus.internalStatus) ? {..._.find(allStatusValues, s => s.name === 'supplier-approved') as Status, date: moment((date && date.includes('.')) ? date.split('.')[0] + 'Z' : date).toDate()} : realStatus;
}

export const filterEventsByInternalStatus = (historyName: HistoryPathType, events: Event[]): Event[] => {
    return !(isExternalApp && historyName === 'reservedNumbers') ? events : [..._.filter(events, e => e.status && !e.status.internalStatus) as Event[]];
}