import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {CommandActions} from '../../../redux/actions/documents';
import Dropzone from 'react-dropzone'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { Document, FileForm } from '../../../models/document';
import { ChoiceGroup, FontIcon, IChoiceGroupOption, Icon, ProgressIndicator, Text, TextField } from '@fluentui/react';
import { ModalDocumentAction, ModalCommandActions } from '../../../redux/actions/modal';
import { getLoader } from '../../../redux/selectors/loader';
import { getDocumentsToUpload, getDocumentsToUploadTotal } from '../../../redux/reducers/documents';
import Scrollbars from 'react-custom-scrollbars-2';
import { getContextualDocuments } from '../../../redux/selectors/documents';
import { getFileExtension } from '../../../utils/functions';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getClassNames } from './index.classNames';

export interface UploadProps extends RouteComponentProps, WithTranslation {
    uploadFileForm: {
        fileForm: FileForm,
        files?: any[]
    },
    loadingContent: boolean,
    documentsToUploadTotal: number,
    documentsToUpload: number,
    canUploadAttachments: boolean,
    canUploadFileComments: boolean,
    loading: boolean,
    documents: Document[],
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty,
    openGenericModal: typeof ModalCommandActions.openModal
}

export interface UploadState {

}

class Upload extends React.Component<UploadProps, UploadState> {
    constructor(props: UploadProps) {
        super(props);   
        this.state = {

        }
    }
   
    onDrop = (accepted: any, rejected: any) => {
        const {t, uploadFileForm, setContextItemProperty, openGenericModal} = this.props;
        if(uploadFileForm && uploadFileForm.fileForm && (!uploadFileForm.fileForm.isParent || (uploadFileForm.fileForm.isParent && accepted.length === 1 )))
            setContextItemProperty({id: 'uploadDocuments', name: 'files', value: accepted})
            //uploadDocuments({files: accepted, total: (accepted) ? accepted.length : 0})
        else
            openGenericModal({id: 'warning', contextItem: t('Only one file can be uploaded')});
    }

    render(){
        const { documents, uploadFileForm, loadingContent, documentsToUploadTotal, documentsToUpload, canUploadAttachments, canUploadFileComments, loading, t, setContextItemProperty } = this.props;
        const currentDoc = (documentsToUpload > 0) ? (documentsToUploadTotal - documentsToUpload + 1) : undefined
        const styles = getClassNames();
        return (
            <div>
                <ProgressIndicator description={(currentDoc) ? (currentDoc + " of " + documentsToUploadTotal) : undefined} styles={{root: {height: 25, marginBottom: 10}}} progressHidden={ !loadingContent && !loading } percentComplete={(currentDoc) ? ((currentDoc) / documentsToUploadTotal) : 0}/> 
                <Text variant='mediumPlus' nowrap>{t('Selected ' + ((documents.length === 1) ? 'document' : 'documents')) + ':'}</Text>
                <Scrollbars autoHeight autoHeightMax={180}>
                    <div style={{marginTop:10, marginBottom: 20}}>
                        {_.map(documents, (sd, index) => {
                            return <div key={index}><Text nowrap>{sd.documentNumber}</Text></div>
                        })}
                    </div>
                </Scrollbars>
                <div style={{display: 'flex', gap: 20, alignSelf: 'center', marginTop: 20}}>

                    <Dropzone
                        onDrop={this.onDrop}
                        disabled={loadingContent}
                        noDragEventsBubbling={true}
                    >
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className={styles.containerDropzone}>
                                <input {...getInputProps()} />
                                <div style={{padding: 5}}>Drop the files here, or click to select files to upload.</div>
                            </div>
                        )}
                    </Dropzone> 
                    {uploadFileForm && uploadFileForm.files && uploadFileForm.files.length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'column', gap: 5, maxHeight: 200, overflowY: 'auto'}}>
                            {_.map(uploadFileForm.files, (f, index) => {
                                return( 
                                    <div key={index + '_imported_file_upload'} style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                                        <Icon {...getFileTypeIconProps({extension: (f.name) ? getFileExtension(f.name) : '', size: 16}) }/>
                                        <Text  variant="smallPlus" style={{}}>{f.name}</Text> 
                                        {!loadingContent && <FontIcon iconName={"Cancel"} style={{cursor: "pointer", color: 'red' }}  onClick={() => { setContextItemProperty({id: 'uploadDocuments', name: 'files', value: _.filter(uploadFileForm.files, of => of.name !== f.name)}) }} /> }
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
                <TextField 
                    autoAdjustHeight
                    multiline
                    rows={3}
                    label="Comment" 
                    autoComplete={'off'} 
                    value={(uploadFileForm && uploadFileForm.fileForm && uploadFileForm.fileForm.comment) || ''} 
                    onChange={(e, value) => { 
                        setContextItemProperty({id: 'uploadDocuments', name: 'fileForm', value: {...uploadFileForm.fileForm, comment: value}})
                    }}
                    styles={{root: {minWidth: 260, marginTop: 15, width: '100%'}}}
                />  
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    uploadFileForm: (state) => (getGenericModal('uploadDocuments')(state)) ? (getGenericModal('uploadDocuments')(state) as any).contextItem : undefined,
    loading: (state) => getLoader('document')(state).loading,
    documentsToUpload: getDocumentsToUpload,
    documentsToUploadTotal: getDocumentsToUploadTotal,
    documents: getContextualDocuments
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalDocumentAction.setContextItemProperty,
        openGenericModal: ModalCommandActions.openModal
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Upload)));