import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {Dropdown, IDropdownOption } from '@fluentui/react';
import { getClassNames } from './languageSelector.classNames';
import _ from 'lodash';
import { translateLanguages } from '../../translations';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../redux/reducers/project';
import { getTheme } from '../../utils/costants';

export interface LanguageSelectorProps extends WithTranslation  { 
    darkModeEnabled: boolean
}

class LanguageSelector extends React.Component<LanguageSelectorProps> {

    constructor(props: LanguageSelectorProps) {
        super(props);   
    }

    _onRenderFlagOption = (option?: IDropdownOption): JSX.Element => {
        const { darkModeEnabled } = this.props;
        const styles = getClassNames(getTheme(darkModeEnabled));
        return (
            <div key={(option) ? option.key : undefined} className={styles.dropDownItem}>
                {option && (option as any).icon}
                <span>{(option) ? option.text : undefined}</span>
            </div>     
        );
    };

    _onRenderFlagTitle = (options?: IDropdownOption[]): JSX.Element => {
        const option = (options && options.length > 0) ? options[0] : undefined;
        return this._onRenderFlagOption(option);
    };

    render(){
        const { i18n, darkModeEnabled } = this.props;
        const styles = getClassNames(getTheme(darkModeEnabled));
        let language_options: {key: string, text: string}[] = _.map(translateLanguages, l => {return { key: l.code, text: l.description}});
        return (
            <div style={{display: 'flex', gap: 10}}>
                <Dropdown
                    options={language_options}
                    onChange={(e, value) => { i18n.changeLanguage((value) ? (value as IDropdownOption).key as string : 'en') }}
                    selectedKey={(i18n && i18n.resolvedLanguage) ? i18n.resolvedLanguage : null}     
                    styles={{ root: styles.root, caretDown: styles.icon, dropdownItemsWrapper: {maxHeight: 360}, caretDownWrapper: {display: 'none'} }}
                />     
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(LanguageSelector));